<template>
	<modal
			@close="$emit('close')"
			class="product-to-warehouse-modal product-to-warehouse"
	>
		<template slot="header">
			Send Product to Another Warehouse?
		</template>
		<template slot="body">
			<div class="product-to-warehouse__content">
				<p>
					Do you want to move the item “men tie red with blue ribbon”?
				</p>

				<div class="amount">
					<div class="amount-fbm">
						<span>Amount on FMB</span>
						<span>23</span>
					</div>
					<div class="amount-fma">
						<span>Amount on FMA</span>
						<span>12</span>
					</div>
				</div>

				<div class="sub-title">
					Warehouse to send to
				</div>

				<div class="product-to-warehouse__radio">
					<div class="custom-row">
						<div class="custom-col custom-col--50 custom-col--md-100">
							<LargeRadio
											:label="'To FMB'"
											:checked="true"
											:name="'orderCreateSwitch'"
											@change="changeSwitch"
											:value="'FMB'"
							/>
						</div>
						<div class="custom-col custom-col--50 custom-col--md-100">
							<LargeRadio
											:label="'to FBA'"
											:name="'orderCreateSwitch'"
											@change="changeSwitch"
											:value="'FBA'"
							/>
						</div>
					</div>
				</div>

				<div class="popup-radio">
					<div class="custom-row">
						<div class="custom-col custom-col--50 custom-col--md-100">
							<DefaultInput
											:label="'Q-ty  to move (max 12)'"
											:errorTxt="'This field is required'"
											v-model="name"
							/>
						</div>
					</div>
				</div>

			</div>
		</template>
		<template slot="footer">
			<div class="product-to-warehouse__btn">
				<span @click="$emit('close')"
										 class="site-link site-link--alt product-to-warehouse__btn-i"
				>
					Cancel
				</span>

				<MainButton
						:value="'Send Items'"
						class="product-to-warehouse__btn-i"
				/>

			</div>
		</template>
	</modal>
</template>

<script>


	import Modal from "../../../../commonModals/Modal";
	import MainButton from "../../../../UI/buttons/MainButton/MainButton";
	import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
	import LargeRadio from "../../../../UI/radiobuttons/LargeRadio/LargeRadio";

	export default {
		name: "ProductsToWarehouse",
		components: {
			Modal,
			MainButton,
			DefaultInput,
			LargeRadio,
		},

		data() {
			return {
				name: '',
				checkedSwitch: ''
			}
		},

		methods: {
			changeSwitch(val) {
				console.log(val);
			}
		}
	}

</script>

<style lang="scss">
	.product-to-warehouse{

		.modal-component__inner {
			max-width: 560px;
		}

		&__content{
			p{
				font-size: 16px;
				line-height: 19px;
			}
		}

		&__btn{
			display: flex;
			align-items: center;
		}

		&__btn-i{
			margin-right: 24px;

			&:last-child{
				margin-right: 0;
			}
		}


		.amount{
			display: flex;
		}

		.amount-fma,
		.amount-fbm{
			font-size: 14px;
			line-height: 16px;
			color: #BBAD9C;
			width: 50%;

			span:last-child{
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #232323;
				margin-left: 10px;
			}
		}

		.sub-title{
			margin-top: 35px;
			margin-bottom: 25px;
			font-weight: 500;
			font-size: 18px;
			line-height: 21px;
			color: #000000;
		}


	}

</style>
