<template>
  <div>
    <ProductsHead
        :showFilter="showFilter"
        :selectedNow="selectedNow"
        :countFilterParams="countFilterParams"
        :navTabs="navTabs"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="changeFilter"
        @changeTab="(data) => $emit('changeTab', data)"
        @selectAll="selectAll"
        @reloadTable="$emit('reloadTable')"
        @massThermalPrint="$emit('massThermalPrint')"
    />

    <div class="table-filter-wrap">

      <ProductsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
      />

      <ProductsTable
          :sortParams="sortParams"
          :selectedNow="selectedNow"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @changeSort="data => $emit('changeSort', data)"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @downloadFiles="type => $emit('downloadFiles', type)"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import ProductsHead from "./ProductsHead/ProductsHead";
  import ProductsTable from "./ProductsTable/ProductsTable";
  import ProductsFilter from "./ProductsFilter/ProductsFilter";

  export default {
    name: "ProductsTableUser",

    components: {
      ProductsHead,
      ProductsTable,
      ProductsFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
      sortParams: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
