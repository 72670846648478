<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-number cursor-pointer"
           @click="$emit('changeInfoIDPopup')"
      >
        #{{item.id}}
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
      <!--<div class="table-card__item-head-label">-->
        <!--<div class="table-card__label-circle table-card__item-head-label-i" style="background-color: #9B51E0;">-->
          <!--L-->
        <!--</div>-->
      <!--</div>-->
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('product_UPC.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.upc_code}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('product_SKU.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.sku_code}}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('product_ProductName.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.name}}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('product_NameStore.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.name_from_shop}}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('product_InTransit.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=transit&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.transit'"
                />
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('product_Reserv.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=reserve&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.reserve'"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('product_FBM.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=all&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.count_in_storage.count_normal'"
                />
              </router-link>
            </div>
          </div>

          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('product_Damaged.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=damage&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.count_in_storage.count_damage'"
                />
              </router-link>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('product_Damaged.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'count.count_in_storage.count_damage'"
              />
            </div>
          </div>
        </div>


        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.products + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                v-if="item.archived === 0"
                :value="$t('common_toArchive.localization_value.value')"
                :type="'sendTo'"
                @click.native="$emit('toggleArchive')"
            />
            <LinkButton
                v-if="item.archived === 1"
                :value="$t('common_fromArchive.localization_value.value')"
                :type="'sendTo'"
                @click.native="$emit('toggleArchive')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  export default {
    name: "ProductsTableMobile",

    components: {ValueHelper, LinkButton},

    props: {
      item: Object,
      getStorageId: Number,
    },

    data() {
      return {
        show: false,
      }
    },
  }
</script>

<style scoped>

</style>
