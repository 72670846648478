<template>
  <div class="fragment">
    <ProductsImportAdmin/>
  </div>
</template>

<script>
  import ProductsImportAdmin from "./ProductsImportAdmin/ProductsImportAdmin";

  export default {
    name: "ProductsImport",
    components: {
      ProductsImportAdmin,
    },
  }
</script>

<style scoped>

</style>