var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page"},[_c('div',{staticClass:"detail-page__left",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'breadcrumbs_NewProduct',
              ])}}}),_c('CardLeftBlock',[_c('template',{slot:"button"},[_c('h2',{staticClass:"mt-0"},[_vm._v(_vm._s(_vm.$t('breadcrumbs_NewProduct.localization_value.value')))]),_c('div',{staticClass:"card-detail-left__date"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"DD MMM, YYYY"))+" ")])])],2)],1),_c('div',{staticClass:"detail-page__right"},[_c('CardRightBlock',[_c('template',{slot:"header"},[_c('div',{staticClass:"order-create__head"},[_c('div',{staticClass:"order-create__head-line"},[_c('div',{staticClass:"order-create__header-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_BackProducts',
              ])}}}),_c('router-link',{staticClass:"order-create__head-back",attrs:{"to":_vm.$store.getters.GET_PATHS.products}},[_c('LinkBack',{attrs:{"value":_vm.$t('product_BackProducts.localization_value.value')}})],1)],1)]),_c('div',{staticClass:"order-create__head-title",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_Product',
              ])}}}),_vm._v(" "+_vm._s(_vm.$t('product_Product.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__head-txt",class:{
               'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate(),
               'mb-0': _vm.$store.getters.getIsAdminRights === 'admin'
               }},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_PleaseFillOut',
              ])}}}),_vm._v(" "+_vm._s(_vm.$t('product_PleaseFillOut.localization_value.value'))+" ")]),(_vm.$store.getters.getIsAdminRights === 'user')?_c('div',{staticClass:"order-create__head-steps",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_ProductDetails','product_SenderInfo','product_Confirmation',
              ])}}}),_c('ProgressSteps',{staticClass:"product-progress-steps",attrs:{"options":_vm.progressStepsSetting,"step":_vm.activeStep},on:{"changeStep":_vm.stepChange,"nextPageHandler":_vm.nextPageHandler}})],1):_vm._e()])]),_c('template',{slot:"body"},[(_vm.activeStep == 1 || _vm.$store.getters.getIsAdminRights === 'admin')?_c('div',[(_vm.$store.getters.getIsAdminRights === 'admin')?_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100"},[_c('UserSelect',{attrs:{"serverError":_vm.serverError,"validationUser":_vm.productItem.validation.user,"validationTxtUser":_vm.productItem.validationTxt.user,"validationTranslateUser":_vm.productItem.validationTranslate.user,"userSelected":_vm.productItem.product.user,"typeSelect":'users',"userEmail":true},on:{"changeUser":_vm.changeUser}})],1)]):_vm._e(),(!_vm.isAdmin)?_c('div',{staticClass:"order-create__title-horizontal",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_Product',
              ])}}}),_c('TitleHorizontal',{attrs:{"value":_vm.$t('product_Product.localization_value.value') + '#1',"rightBtn":true}})],1):_vm._e(),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_ProductName',
              ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.productItem.validation.productName},attrs:{"label":_vm.$t('product_ProductName.localization_value.value'),"errorTxt":_vm.serverError ? _vm.productItem.validationTxt.productName : _vm.$t(`${_vm.productItem.validationTranslate.productName}.localization_value.value`),"error":_vm.productItem.validation.productName},model:{value:(_vm.productItem.product.productName),callback:function ($$v) {_vm.$set(_vm.productItem.product, "productName", $$v)},expression:"productItem.product.productName"}})],1),_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_NameStore',
              ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.productItem.validation.productNameFromShop},attrs:{"label":_vm.$t('product_NameStore.localization_value.value'),"errorTxt":_vm.serverError ? _vm.productItem.validationTxt.productNameFromShop : _vm.$t(`${_vm.productItem.validationTranslate.productNameFromShop}.localization_value.value`),"error":_vm.productItem.validation.productNameFromShop},model:{value:(_vm.productItem.product.productNameFromShop),callback:function ($$v) {_vm.$set(_vm.productItem.product, "productNameFromShop", $$v)},expression:"productItem.product.productNameFromShop"}})],1),(_vm.isAdmin)?_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_IHaveUPCCode',
              ])}}}),(!_vm.isAdmin)?_c('DefaultCheckbox',{staticClass:"mb-2",attrs:{"label":_vm.$t('product_IHaveUPCCode.localization_value.value')},model:{value:(_vm.product.product.isUPC),callback:function ($$v) {_vm.$set(_vm.product.product, "isUPC", $$v)},expression:"product.product.isUPC"}}):_vm._e(),_c('div',{class:{'hide-upc': !_vm.product.product.isUPC && !_vm.isAdmin}},[_c('div',{staticClass:"mt-3",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_UPCCode',
              ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.productItem.validation.UPC},attrs:{"label":_vm.$t('product_UPCCode.localization_value.value'),"errorTxt":_vm.serverError ? _vm.productItem.validationTxt.UPC : _vm.$t(`${_vm.productItem.validationTranslate.UPC}.localization_value.value`),"error":_vm.productItem.validation.UPC,"maxLength":'20'},model:{value:(_vm.product.product.UPC),callback:function ($$v) {_vm.$set(_vm.product.product, "UPC", $$v)},expression:"product.product.UPC"}})],1)])],1):_vm._e(),(_vm.isAdmin)?_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_SKU',
              ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.productItem.validation.SKU},attrs:{"label":_vm.$t('product_SKU.localization_value.value'),"errorTxt":_vm.serverError ? _vm.productItem.validationTxt.SKU : _vm.$t(`${_vm.productItem.validationTranslate.SKU}.localization_value.value`),"error":_vm.productItem.validation.SKU},model:{value:(_vm.product.product.SKU),callback:function ($$v) {_vm.$set(_vm.product.product, "SKU", $$v)},expression:"product.product.SKU"}})],1)]):_vm._e()]),_c('ProductItemCreation',{class:{'disabled-product':
                _vm.$store.getters.getIsAdminRights === 'admin' && Object.keys(_vm.product.product.user).length === 0},attrs:{"product":_vm.product,"canChangeHSCode":true,"serverError":_vm.serverError,"altVersion":true},on:{"changeCategory":({val, nameVal}) => _vm.$emit('changeCategory', {val, nameVal, item: _vm.product}, null, true),"changeProformSelectItem":function($event){return _vm.changeProformSelectItem(_vm.product)}}}),_c('div',{staticClass:"order-create__total"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_TotalShippingCost',
              ])}}}),_vm._v(" "+_vm._s(_vm.$t('product_TotalShippingCost.localization_value.value'))+" ")]),_c('span',[_vm._v(" $"+_vm._s(_vm._f("number")(_vm.product.product.price * _vm.product.product.itemsQuantity,'0.00'))+" ")])])],1):_vm._e(),(_vm.activeStep == 2 || _vm.$store.getters.getIsAdminRights === 'admin')?_c('div',[_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_ProductName',
              ])}}}),_vm._v(" "+_vm._s(_vm.$t('profile_Documents.localization_value.value'))+" ("+_vm._s(_vm.$t('common_upTo.localization_value.value'))+" 5) ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col"},[_c('DropZone',{staticClass:"drop-zone-bg",attrs:{"parentFiles":_vm.productItem.product.Files.data.files,"maxSize":'3',"maxCount":_vm.productItem.product.Files.data.maxCountFiles,"accept":'image/x-png,image/jpeg,'},on:{"changeImg":_vm.changeImg}})],1)]),_c('MediaFiles',{attrs:{"product":_vm.productItem}}),(!_vm.isAdmin)?_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col mb-0"},[_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_Codes',
              ])}}}),_vm._v(" "+_vm._s(_vm.$t('common_Codes.localization_value.value'))+" ")])]),_c('div',{staticClass:"custom-col custom-col--66 custom-col--xl-50 custom-col--xs-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_SKU',
              ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.productItem.validation.SKU},attrs:{"label":_vm.$t('product_SKU.localization_value.value'),"errorTxt":_vm.serverError ? _vm.productItem.validationTxt.SKU : _vm.$t(`${_vm.productItem.validationTranslate.SKU}.localization_value.value`),"error":_vm.productItem.validation.SKU},model:{value:(_vm.product.product.SKU),callback:function ($$v) {_vm.$set(_vm.product.product, "SKU", $$v)},expression:"product.product.SKU"}})],1)]):_vm._e(),(!_vm.isAdmin)?_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_IHaveUPCCode',
              ])}}}),_c('DefaultCheckbox',{staticClass:"mb-2",attrs:{"label":_vm.$t('product_IHaveUPCCode.localization_value.value')},model:{value:(_vm.product.product.isUPC),callback:function ($$v) {_vm.$set(_vm.product.product, "isUPC", $$v)},expression:"product.product.isUPC"}}),_c('div',{staticClass:"custom-row",class:{'hide-upc': !_vm.product.product.isUPC}},[_c('div',{staticClass:"custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 mt-3 ml-4",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_UPCCode',
              ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.productItem.validation.UPC},attrs:{"label":_vm.$t('product_UPCCode.localization_value.value'),"errorTxt":_vm.serverError ? _vm.productItem.validationTxt.UPC : _vm.$t(`${_vm.productItem.validationTranslate.UPC}.localization_value.value`),"error":_vm.productItem.validation.UPC,"maxLength":'20'},model:{value:(_vm.product.product.UPC),callback:function ($$v) {_vm.$set(_vm.product.product, "UPC", $$v)},expression:"product.product.UPC"}})],1)])],1)]):_vm._e()],1):_vm._e(),(_vm.activeStep == 3 && _vm.$store.getters.getIsAdminRights === 'user')?_c('div',[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_Product',
              ])}}}),_c('TitleHorizontal',{attrs:{"value":_vm.$t('product_Product.localization_value.value'),"rightBtn":true,"rightBtnType":'edit',"rightBtnText":'Edit'},on:{"rightBtnClick":function($event){_vm.activeStep = 1}}})],1),_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_NewProduct',
              ])}}}),_vm._v(" "+_vm._s(_vm.$t('product_NewProduct.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"},[_c('div',{staticClass:"confirm-product-card"},[_c('div',{staticClass:"confirm-product-card__name word-break"},[_vm._v(" "+_vm._s(_vm.product.product.productName)+" ")]),_c('div',{staticClass:"confirm-product-card__items"},[(_vm.product.product.proformData.proformCategory !== '')?_c('div',{staticClass:"confirm-product-card__item"},[_c('div',{staticClass:"confirm-product-card__item-name",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'proform_category',
                      ])}}}),_vm._v(" "+_vm._s(_vm.$t('proform_category.localization_value.value'))+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(_vm.product.product.proformData.proformCategory.translationStorage.en.name)+" ")])]):_vm._e(),(_vm.localProformTypes = _vm.productItem.product.proformData.proformTypes)?[_vm._l((_vm.$store.getters.getProformProductInitialize.proformTypes),function(itemType,index){return [(_vm.isUkrainian)?_c('div',{key:index,staticClass:"confirm-product-card__item"},[_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_Ua'].name)+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(_vm.localProformTypes[itemType.id].typeValue !== '' ? _vm.localProformTypes[itemType.id].typeValue.value.translationStorage.ua.name : '')+" ")])]):_vm._e(),_c('div',{key:index + '2',staticClass:"confirm-product-card__item"},[_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name)+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(_vm.localProformTypes[itemType.id].typeValue !== '' ? _vm.localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : '')+" ")])])]})]:_vm._e()],2),(_vm.product.product.HSCode !== '')?_c('div',{staticClass:"confirm-product-card__item",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                    'common_HSCode',
                  ])}}}),_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.$t('common_HSCode.localization_value.value'))+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(_vm.product.product.HSCode)+" ")])]):_vm._e(),_c('div',{staticClass:"confirm-product-card__item",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['express_Price',])}}}),_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.$t('express_Price.localization_value.value'))+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(_vm.product.product.price)+" ")])]),_c('div',{staticClass:"confirm-product-card__item",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['express_Quantity',])}}}),_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.$t('express_Quantity.localization_value.value'))+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(_vm.product.product.itemsQuantity)+" ")])]),(_vm.product.product.handMade)?_c('div',{staticClass:"confirm-product-card__bottom",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'product_Handmade',
                      ])}}}),_c('StatusIcoBtn',{staticClass:"mr-2",attrs:{"type":'handmade'}}),_vm._v(" "+_vm._s(_vm.$t('product_Handmade.localization_value.value'))+" ")],1):_vm._e()])])]),_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'common_SenderInfo',
                      ])}}}),_c('TitleHorizontal',{attrs:{"value":_vm.$t('common_SenderInfo.localization_value.value'),"rightBtn":true,"rightBtnType":'edit',"rightBtnText":'Edit'},on:{"rightBtnClick":function($event){_vm.activeStep = 2}}})],1),_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'profile_Documents',
                      ])}}}),_vm._v(" "+_vm._s(_vm.$t('profile_Documents.localization_value.value'))+" ")]),(_vm.product.product.Files.data.files.length > 0)?_c('div',{staticClass:"custom-row"},[_vm._l((_vm.product.product.Files.data.files),function(item,index){return [(_vm.product.product.Files.data.maxCountFiles > index && !item.maxSizeError)?_c('div',{key:index,staticClass:"custom-col mb-3"},[_c('span',{staticClass:"table-link"},[_vm._v(_vm._s(item.name))])]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"custom-row mt-2"},[(_vm.product.product.isUPC)?_c('div',{staticClass:"custom-col block-table__col--20 block-table__col--sm-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'product_IHaveUPCCode',
                      ])}}}),_c('div',{staticClass:"section-label mb-2"},[_vm._v(" "+_vm._s(_vm.$t('product_IHaveUPCCode.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content word-break"},[_vm._v(" "+_vm._s(_vm.product.product.UPC)+" ")])]):_vm._e(),(_vm.product.product.SKU.length > 0)?_c('div',{staticClass:"custom-col block-table__col--20 block-table__col--sm-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'product_SKU',
                      ])}}}),_c('div',{staticClass:"section-label mb-2"},[_vm._v(" "+_vm._s(_vm.$t('product_SKU.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content word-break"},[_vm._v(" "+_vm._s(_vm.product.product.SKU)+" ")])]):_vm._e()])]):_vm._e()]),_c('template',{slot:"footer"},[_c('div',{staticClass:"order-create__footer"},[_c('div',{staticClass:"order-create__footer-link",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'common_cancel',
                        'common_Previous',
                      ])}}}),(_vm.activeStep == 1)?_c('router-link',{staticClass:"site-link site-link--alt",attrs:{"to":_vm.$store.getters.GET_PATHS.products}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]):_vm._e(),(_vm.activeStep != 1)?_c('span',{staticClass:"site-link site-link--alt",on:{"click":function($event){return _vm.prevPageHandler()}}},[_vm._v(" "+_vm._s(_vm.$t('common_Previous.localization_value.value'))+" ")]):_vm._e()],1),_c('div',{staticClass:"order-create__footer-btn ml-auto",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'common_SaveInDrafts',
                        'common_drafts',
                        'common_savingInDrafts',
                        'common_Next',
                        'common_create',
                      ])}}}),(_vm.activeStep === _vm.progressStepsSetting.length && _vm.$store.getters.getIsAdminRights === 'user')?_c('MainButton',{staticClass:"order-create__footer-btn-i secondary",class:{'disabled-btn' : _vm.$store.getters.getProductsBtn},attrs:{"value":_vm.$t('common_SaveInDrafts.localization_value.value'),"tooltip":true},nativeOn:{"click":function($event){return _vm.$emit('createProduct', true)}}},[_c('template',{slot:"tooltip"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('common_drafts.localization_value.value')))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('common_savingInDrafts.localization_value.value'))+" ")])])],2):_vm._e(),(_vm.activeStep !== _vm.progressStepsSetting.length && _vm.$store.getters.getIsAdminRights === 'user')?_c('MainButton',{staticClass:"order-create__footer-btn-i",attrs:{"value":_vm.$t('common_Next.localization_value.value')},nativeOn:{"click":function($event){return _vm.nextPageHandler()}}}):_c('MainButton',{staticClass:"order-create__footer-btn-i",class:{'disabled-btn' : _vm.$store.getters.getProductsBtn},attrs:{"value":_vm.$t('common_create.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('createProduct', false)}}})],1)])])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }