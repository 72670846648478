var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"content-top-line content-top-line--with-bottom-line"},[_c('div',{staticClass:"content-tabs content-tabs--separator",class:{'loading-tabs': _vm.$store.getters.getProductsLoading === true}},[_vm._l((_vm.navTabs),function(item,index){return [_c('div',{key:index,staticClass:"content-tabs__item",class:{active: item.active},on:{"click":function($event){return _vm.$emit('changeTab', item.name)}}},[_c('div',{staticClass:"content-tabs__ico",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[[_vm._v(" "+_vm._s(item.label)+" ")]],2)])]})],2),_c('div',{staticClass:"content-top-line-wrap"},[_c('ToggleFilterButton',{attrs:{"count":_vm.countFilterParams,"showFilter":_vm.showFilter},on:{"toggleFilter":function($event){return _vm.$emit('toggleFilter')}}})],1)]),_c('div',{staticClass:"light-bg-block"},[_c('div',{staticClass:"btn-left-block"},[_c('DefaultCheckbox',{attrs:{"label":_vm.$t('warehouseManagement_selectAll.localization_value.value')},on:{"input":(val) => _vm.$emit('selectAll', val)}})],1),_c('div',{staticClass:"btn-right-block"},[(_vm.isAdmin)?_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'product_UPC',
          ])}}}),_c('DefaultInput',{staticClass:"mr-2 btn-right-block-input",staticStyle:{"max-width":"150px"},attrs:{"label":_vm.$t('product_UPC.localization_value.value'),"type":'text',"name":'upcProduct'},on:{"change":_vm.findProductByUPC}})],1):_vm._e(),(_vm.isAdmin)?_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'product_goodsRelocation',
          ])}}}),_c('MainButton',{staticClass:"btn-fit-content brown mr-3",attrs:{"value":_vm.$t('product_goodsRelocation.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('goodsRelocation')}}})],1):_vm._e(),_c('div',{staticClass:"content-top-line-btn ml-2 mr-2",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'common_termalPrint',
        ])}}}),_c('TableHeadButton',{attrs:{"value":_vm.$t('common_termalPrint.localization_value.value'),"ico":'print'},nativeOn:{"click":function($event){return _vm.$emit('massThermalPrint')}}})],1),_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'common_AddNew',
          ])}}}),_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.productsCreate}},[_c('MainButton',{staticClass:"btn-fit-content",attrs:{"value":_vm.$t('common_AddNew.localization_value.value'),"ico":'ico'}},[_c('template',{slot:"ico"},[_c('PlusIconSVG',{staticClass:"btn-icon-plus"})],1)],2)],1)],1)])]),(_vm.moveProductCell)?_c('MoveProductCell',{attrs:{"product":_vm.moveProduct,"navTabs":_vm.navTabs},on:{"close":function($event){_vm.moveProductCell = false},"moveSuccess":function($event){_vm.moveProductCell = false; _vm.$emit('reload')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }