var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.product.product.serviceProduct)?_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col mb-0"},[_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_Medias',
              ])}}}),_vm._v(" "+_vm._s(_vm.$t('common_Medias.localization_value.value'))+" ")])]),_c('div',{staticClass:"custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_Media',
              ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('common_Media.localization_value.value')},model:{value:(_vm.product.product.medias[0].media_url),callback:function ($$v) {_vm.$set(_vm.product.product.medias[0], "media_url", $$v)},expression:"product.product.medias[0].media_url"}})],1),_c('div',{staticClass:"custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_Media',
              ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('common_Media.localization_value.value')},model:{value:(_vm.product.product.medias[1].media_url),callback:function ($$v) {_vm.$set(_vm.product.product.medias[1], "media_url", $$v)},expression:"product.product.medias[1].media_url"}})],1),_c('div',{staticClass:"custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_Media',
              ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('common_Media.localization_value.value')},model:{value:(_vm.product.product.medias[2].media_url),callback:function ($$v) {_vm.$set(_vm.product.product.medias[2], "media_url", $$v)},expression:"product.product.medias[2].media_url"}})],1),_c('div',{staticClass:"custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_Media',
              ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('common_Media.localization_value.value')},model:{value:(_vm.product.product.medias[3].media_url),callback:function ($$v) {_vm.$set(_vm.product.product.medias[3], "media_url", $$v)},expression:"product.product.medias[3].media_url"}})],1),_c('div',{staticClass:"custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_Media',
              ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('common_Media.localization_value.value')},model:{value:(_vm.product.product.medias[4].media_url),callback:function ($$v) {_vm.$set(_vm.product.product.medias[4], "media_url", $$v)},expression:"product.product.medias[4].media_url"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }