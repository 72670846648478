<template>
  <div class="table-row">
    <template v-if="filteredCells.length > 0">
      <div class="transactions-row mr-2"
      >
        <router-link
            v-for="(cell, index) in filteredCells"
            :to="$store.getters.GET_PATHS.financeReportCells + '?id=' + cell.cell.id"
            :key="index"
            class="table-link btn-style text-decoration-none">
          <template
              v-if="index === 0 && cell.cell">
            {{ cell.cell.combo_name }}
          </template>
        </router-link>
      </div>

      <TooltipTextHelperPlugin
          v-if="filteredCells.length > 1"
          :fullTextWidth="150"
      >
        <template slot="text">
          <CountCircle
              :value="'+' + (filteredCells.length - 1)"
          />
        </template>
        <template slot="paragraph">
          <p><b>{{ $t('product_Shelves.localization_value.value') }}</b></p>
          <p>
            <template v-for="(cell, index) in filteredCells">
              <router-link
                  :to="$store.getters.GET_PATHS.financeReportCells + '?id=' + cell.cell.id"
                  class="table-link fsz11 btn-style tran-0 mr-1 text-decoration-none"
                  :key="index"
                  v-if="index !== 0 && cell.cell"
              >{{ cell.cell.combo_name }} <span
                  v-if="index < filteredCells.length - 1">,</span></router-link>
            </template>
          </p>
        </template>
      </TooltipTextHelperPlugin>
    </template>
  </div>
</template>

<script>
import CountCircle from "@/components/coreComponents/CountCircle/CountCircle";
import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";

export default {
  name: "CellsBlock",
  components: {TooltipTextHelperPlugin, CountCircle},
  props: {
    cells: Array,
  },

  data() {
    return {
      filteredCells: [],
    }
  },

  watch: {
    cells() {
      this.filterCellByName()
    },
  },

  mounted() {
    this.filterCellByName()
  },

  methods: {
    filterCellByName() {

      this.filteredCells = this.cells.sort(
          (a,b) =>
              (a?.cell?.combo_name.split('-')[1] < b?.cell?.combo_name.split('-')[1]) ? 1 :
              (
                  (b?.cell?.combo_name.split('-')[1] < a?.cell?.combo_name.split('-')[1]) ? -1 :
              0))

      // let notSectionZero = null
      // let newCellsArray = []
      //
      // this.cells.map(cellItem => {
      //   if(!notSectionZero && cellItem?.cell?.combo_name.split('-')[1] !== '0') {
      //     notSectionZero = cellItem
      //   } else {
      //     newCellsArray.push(cellItem)
      //   }
      // })
      //
      // if(notSectionZero) {
      //   newCellsArray.unshift(notSectionZero)
      // }


    },
  },

}
</script>

<style scoped>

</style>