<template>
  <div>
    <div v-if="product.product.serviceProduct" class="custom-row">
      <div class="custom-col mb-0">
        <div class="section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'common_Medias',
                ])"></span>
          {{$t('common_Medias.localization_value.value')}}
        </div>
      </div>
      <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'common_Media',
                ])"></span>
        <DefaultInput
            :label="$t('common_Media.localization_value.value')"
            v-model="product.product.medias[0].media_url"
        />
      </div>
      <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'common_Media',
                ])"></span>
        <DefaultInput
            :label="$t('common_Media.localization_value.value')"
            v-model="product.product.medias[1].media_url"
        />
      </div>
      <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'common_Media',
                ])"></span>
        <DefaultInput
            :label="$t('common_Media.localization_value.value')"
            v-model="product.product.medias[2].media_url"
        />
      </div>
      <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'common_Media',
                ])"></span>
        <DefaultInput
            :label="$t('common_Media.localization_value.value')"
            v-model="product.product.medias[3].media_url"
        />
      </div>
      <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'common_Media',
                ])"></span>
        <DefaultInput
            :label="$t('common_Media.localization_value.value')"
            v-model="product.product.medias[4].media_url"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
export default {
  name: "MediaFiles",
  components: {DefaultInput},

  props: {
    product: Object,
  },

}
</script>

<style scoped>

</style>