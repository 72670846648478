import {checkValid} from "@/components/globalModels/functions/ModelsValidate";
import {ProductForCell} from "@/components/globalModels/ProductForCell";
import {INVENTORY_TYPES, WAREHOUSE_STORAGE_DOCUMENT_TYPES} from "@/staticData/staticVariables";

const _ = require('lodash');


export function MoveProductCell() {

  this.checkValid = checkValid

  this.data = {
    ProductForCell: new ProductForCell(),
    productsForCellArray: [new ProductForCell()],
  }

  this.validation = {
    
  }

  this.validationTranslate = {
    
  }

  this.validationTxt = {
    
  }

  /**
   * Getters
   */
  
  this.getProductsForCellArray = () => {
    return this.data.productsForCellArray
  }


  /**
   * Setters
   */
 
  this.setProductsForCellArray = (val) => {
    this.data.productsForCellArray = val
  }
  
}


/**
 * Global Setters
 */

MoveProductCell.prototype.setItem = function (item, isAdmin, edit = false) {
  console.log(item, isAdmin, edit);
}


/**
 * Functions
 */


// MoveProductCell.prototype.addProductForCells = function (product = false, cells = false) {
//   let productForCell = new ProductForCell(product)
//   if(cells.length > 0){
//     productForCell.setCellsByType(cells)
//   }
//   this.data.productsForCellArray.push(productForCell)
//   return this.data.productsForCellArray.length - 1
// }

// MoveProductCell.prototype.setFiles = function (val) {
//   this.data.Documents.pushDownloadFiles(val)
// }

// MoveProductCell.prototype.removeFile = function (id) {
//   let i = -1
//   _.findIndex(this.data.Documents.getDownloadFiles(), function (item, index) {
//     if (item[id] !== undefined) i = index
//   });
//
//   this.data.Documents.removeDownloadFile(i)
// }

MoveProductCell.prototype.productsDataGeneration = function (userId, storageId) {
  let item = _.first(this.data.productsForCellArray)

  let product = item.data.ProformProduct.product
  let productId = product.productObject?.id

  if(!productId) return


  let itemData = {
    "storage_id": storageId ? storageId : 1,
    "inventory_type": INVENTORY_TYPES.MOVE,
    "type_id": WAREHOUSE_STORAGE_DOCUMENT_TYPES.TYPE_INVENTORY,
    "user_id": userId,
    "status": 'completed',
    "comment": null,
    "costs": null,
    "requested_date": null,
    "products": [],
  }

  let cells = [],
    userProductCellArray = item.data.UserProductCellArray,
    userMixedProductCellArray = item.data.UserMixedProductCellArray,
    userCellArray = item.data.UserCellArray,
    mixedCellArray = item.data.MixedCellArray,
    freeCellArray = item.data.FreeCellArray

  cells = [...cells, ...this.getCellsFromArray(userProductCellArray, productId, true)]
  cells = [...cells, ...this.getCellsFromArray(userMixedProductCellArray, productId, true)]
  cells = [...cells, ...this.getCellsFromArray(userCellArray, productId)]
  cells = [...cells, ...this.getCellsFromArray(mixedCellArray, productId)]
  cells = [...cells, ...this.getCellsFromArray(freeCellArray, productId)]

  cells.push({
    "product_id": productId,
    "cell_id": null,
    "quantity": parseInt(item.data.withoutCellEndCounts),
    "damage": false,
  })

  itemData["products"] = cells

  return itemData
}

MoveProductCell.prototype.getCellsFromArray = function (array, productId, allItems = false) {
  let cells = []
  array.map(itemCell => {
    if(itemCell.data.cellCount > 0 || allItems){
      cells.push({
        "product_id": productId,
        "cell_id": itemCell.data.cellSelected.id,
        "quantity": parseInt(itemCell.data.cellCount),
        "damage": false,
      })
    }
  })

  return cells
}

/**
 * Validations
 */


/**
 * Prepare Data
 */

MoveProductCell.prototype.prepareSave = function ({isAdmin = false, edit = false}) {


  let productsForCellArray = this.data.productsForCellArray
  
  
  let products = []
  
  if(isAdmin && edit){
    products = this.productsDataGeneration(productsForCellArray)
  }
  
  let prepareData = {
    'products': products,
  }

  return prepareData

}
