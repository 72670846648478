import {checkValid} from "./functions/ModelsValidate";

export function Product() {

  this.checkValid = checkValid

  this.product = {
    id: '',
    productObject: '',
    name: '',
    description: '',
    count: 1,
    price: 0,
    internationalPrice: 0,
    skuCode: '',
    totalSum: 0,
    hsCode: '',

    productModelId: '',
  }

  this.validation = {
    name: false,
    productObject: false,
    description: false,
    count: false,
    price: false,
    internationalPrice: false,
    totalSum: false,
    hsCode: false,
  }

  this.validationTranslate = {
    name: '',
    productObject: '',
    description: '',
    count: '',
    price: '',
    internationalPrice: '',
    totalSum: '',
    hsCode: '',
  }

  this.validationTxt = {
    name: false,
    productObject: false,
    description: false,
    count: false,
    price: false,
    internationalPrice: false,
    totalSum: false,
    hsCode: false,
  }

}


/*****************   SETTERS   ******************/
Product.prototype.setProduct = function(val, customVal = false){
  this.product.productObject = val

  /**
   * default Product data
   */
  if(!customVal){
    this.setId(val.id)
    this.setProductName(val.name)
    this.setProductDescription(val?.description ? val.description : '')
    this.setProductCount(parseInt(val?.count) ? val.count : 0)
    this.setProductPrice(val?.price ? val.price : 0)
    this.setProductSkuCode(val?.sku_code ? val.sku_code : '')
    this.setHsCode(val?.hs_code ? val?.hs_code : '')
    // this.setProductTotalSum(val?.total ? val.total : '')
    return
  }

  this.setId(customVal.id)
  this.setProductModelId(customVal?.productModelId ? customVal.productModelId : '')
  this.setProductName(customVal.name)
  this.setProductDescription(customVal?.description ? customVal.description : '')
  this.setProductCount(customVal?.count ? customVal.count : 0)
  this.setProductPrice(customVal?.price ? customVal.price : 0)
  this.setProductSkuCode(customVal?.sku_code ? customVal.sku_code : '')
  this.setHsCode(customVal?.hs_code ? customVal?.hs_code : '')
  // this.setProductInternationalPrice()
  this.setProductTotalSum(customVal?.total ? customVal.total : '')
}

Product.prototype.setProductObject = function(val){
  this.product.productObject = val
}

Product.prototype.setId = function(val){
  this.product.id = val
}

Product.prototype.setProductModelId = function(val){
  this.product.productModelId = val
}

Product.prototype.setProductName = function(val){
  this.product.name = val
}

Product.prototype.setProductDescription = function(val){
  this.product.description = val;
}

Product.prototype.setProductCount = function(val){
  this.totalSumComputation()
  this.product.count = val
}

Product.prototype.setProductPrice = function(val){
  this.totalSumComputation()
  this.product.price = val
}

Product.prototype.setProductInternationalPrice = function(val){
  this.product.internationalPrice = val
}

Product.prototype.setProductTotalSum = function(val){
  this.product.totalSum = val
}

Product.prototype.setProductSkuCode = function(val){
  this.product.skuCode = val
}

Product.prototype.setHsCode = function(val){
  this.product.hsCode = val
}

Product.prototype.setProductItem = function(val){
  this.product.personalName = val.personalName;
  this.product.personalPhone = val.personalPhone;
  this.product.personalEmail = val.personalEmail;
}


/*****************   GETTERS   ******************/
Product.prototype.getProductId = function(){
  return this.product.id
}

Product.prototype.getProduct = function(){
  return this.product.productObject
}

Product.prototype.getProductModelId = function(){
  return this.product.productModelId
}

Product.prototype.getProductDescription = function(){
  return this.product.description
}

Product.prototype.getProductCount = function(){
  return this.product.count
}

Product.prototype.getProductPrice = function(){
  return this.product.price
}

Product.prototype.getProductInternationalPrice = function(){
  return this.product.internationalPrice
}

Product.prototype.getProductTotalSum = function(){
  return this.product.totalSum
}

Product.prototype.getHsCode = function(){
  return this.product.hsCode
}

Product.prototype.isSelectedProduct = function(){
  return this.getProductId() !== ''
}

/*******************   FUNCTIONS   *************/


Product.prototype.totalSumComputation = function() {
  let quantity = this.product.count,
      price = this.product.price
  this.setProductTotalSum(parseFloat(quantity * price).toFixed(2))
}



/*****************   VALIDATION   ******************/

Product.prototype.productValidate = function(
  {
    // name,
    productObject,
    description,
    count,
    price,
    hsCode,
    // internationalPrice,
  }){

  let validationItems = {
    name: this.product.name,
    productObject: this.product.productObject,
    description: this.product.description,
    count: this.product.count,
    price: this.product.price,
    internationalPrice: this.product.internationalPrice,
    totalSum: this.product.totalSum,
    hsCode: this.product.hsCode,
  }


  let validationOptions = {
    // name: {type: name ? ['empty'] : []},
    productObject: {type: productObject ? ['empty'] : []},
    count: {type: count ? ['not-zero'] : []},
    description: {type: description ? ['latin', 'empty'] : []},
    price: {type: price ? ['not-zero', 'empty'] : []},
    // count: {type: ['email', 'empty']},
    hsCode: {type: hsCode ? ['empty'] : []},
    // internationalPrice: {type: ['email', 'empty']},
    // totalSum: {type: ['email', 'empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}

Product.prototype.productPartialValidate = function(
  {
    name,
    productObject,
    count,
    // price,
  }){

  let validationItems = {
    name: this.product.name,
    productObject: this.product.productObject,
    count: this.product.count,
    // price: this.product.price,
  }


  let validationOptions = {
    name: {type: name ? ['empty'] : []},
    productObject: {type: productObject ? ['empty'] : []},
    count: {type: count ? ['not-zero'] : []},
    // price: {type: ['email', 'empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}
