<template>
  <FilterBlock :showFilter="checkShow" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">


      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['product_UPC'])"></span>
        <DefaultInput
            :label="$t('product_UPC.localization_value.value')"
            :type="'text'"
            v-model="UPC"
        />
      </div>

      <template v-if="showFilter">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_id'])"></span>
          <DefaultInput
              :label="$t('product_id.localization_value.value')"
              :type="'text'"
              v-model="id"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['requestReturn_Date'])"></span>
          <DatePickerDefault
              :label="$t('requestReturn_Date.localization_value.value')"
              v-model="date"
          >
            <template slot="body">
              <date-picker
                  v-model="date"
                  ref="datePicker"
                  valueType="format"
                  range
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  :format="'MM/DD/YY'"
                  :placeholder="'mm/dd/yy'"
              ></date-picker>
            </template>
          </DatePickerDefault>
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
          <SearchSelect
              :options="options"
              :label="$t('shop_user.localization_value.value')"
              :optionsLabel="'email.contacts[0]'"
              :functionSearch="functionSearch"
              @change="changeUsersFilter"
              :selected="userName"
              :typeSelect="'users'"
              :userEmail="true"
          />
        </div>





        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_SKU'])"></span>
          <DefaultInput
              :label="$t('product_SKU.localization_value.value')"
              :type="'text'"
              v-model="SKU"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_ProductName'])"></span>
          <DefaultInput
              :label="$t('product_ProductName.localization_value.value')"
              :type="'text'"
              v-model="productName"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_NameStore'])"></span>
          <DefaultInput
              :label="$t('product_NameStore.localization_value.value')"
              :type="'text'"
              v-model="nameInStore"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_InTransit'])"></span>
          <DefaultInput
              :label="$t('product_InTransit.localization_value.value')"
              :type="'text'"
              v-model="inTransit"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_Reserv'])"></span>
          <DefaultInput
              :label="$t('product_Reserv.localization_value.value')"
              :type="'text'"
              v-model="reserved"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_FBM'])"></span>
          <DefaultInput
              :label="$t('product_FBM.localization_value.value')"
              :type="'text'"
              v-model="FBM"
          />
        </div>
        <!--      <div class="table-filter__item"-->
        <!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--        <span class="admin-edit" @click="editTranslate(['product_FBA'])"></span>-->
        <!--        <DefaultInput-->
        <!--                :label="$t('product_FBA.localization_value.value')"-->
        <!--                :type="'text'"-->
        <!--                v-model="FBA"-->
        <!--        />-->
        <!--      </div>-->
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_Damaged'])"></span>
          <DefaultInput
              :label="$t('product_Damaged.localization_value.value')"
              :type="'text'"
              v-model="damaged"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_Shelves'])"></span>
          <DefaultInput
              :label="$t('product_Shelves.localization_value.value')"
              :type="'text'"
              v-model="cellName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['product_inArchive', 'product_notInArchive'])"></div>
          <DefaultSelect
              :options="archiveOptions"
              :label="$t('product_inArchive.localization_value.value')"
              :otherValue="'translation'"
              :selected="archive ? _.find(archiveOptions, {value: archive}) : null"
              @change="changeArchiveFilter"
          />
        </div>
      </template>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['product_Shelves'])"></span>-->
<!--        <DefaultInput-->
<!--                :label="$t('product_Shelves.localization_value.value')"-->
<!--                :type="'text'"-->
<!--                v-model="shelves"-->
<!--        />-->
<!--      </div>-->

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import {mixinDetictingMobile} from "@/mixins/mobileFunctions";

  export default {
    name: "ProductsFilter",
    components: {
      DefaultSelect,
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc, mixinDetictingMobile],

    data(){
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        productName: this.filterGetParams.productName ? this.filterGetParams.productName : '',
        UPC: this.filterGetParams.UPC ? this.filterGetParams.UPC : '',
        SKU: this.filterGetParams.SKU ? this.filterGetParams.SKU : '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        nameInStore: this.filterGetParams.nameInStore ? this.filterGetParams.nameInStore : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        inTransit: this.filterGetParams.inTransit ? this.filterGetParams.inTransit : '',
        reserved: this.filterGetParams.reserved ? this.filterGetParams.reserved : '',
        FBM: this.filterGetParams.FBM ? this.filterGetParams.FBM : '',
        FBA: this.filterGetParams.FBA ? this.filterGetParams.FBA : '',
        damaged: this.filterGetParams.damaged ? this.filterGetParams.damaged : '',
        shelves: this.filterGetParams.shelves ? this.filterGetParams.shelves : '',
        cellName: this.filterGetParams.cellName ? this.filterGetParams.cellName : '',

        archive: this.filterGetParams.archive ? this.filterGetParams.archive : '',

        options: [],

        filterCounts: [
          'date',
          'productName',
          'UPC',
          'SKU',
          'id',
          'nameInStore',
          'userName',
          'userId',

          'inTransit',
          'reserved',
          'FBM',
          'FBA',
          'damaged',
          'shelves',
          'cellName',

          'archive',
        ],
        //
        // inTransit: '',
        // reserved: '',
        // FBM: '',
        // FBA: '',
        // damaged: '',
        // shelves: '',

        archiveOptions: [
          {translation: 'product_inArchive', value: '1'},
          {translation: 'product_notInArchive', value: '0'},
        ]
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.productName = newVal.productName ? newVal.productName : ''
        this.UPC = newVal.UPC ? newVal.UPC : ''
        this.SKU = newVal.SKU ? newVal.SKU : ''
        this.id = newVal.id ? newVal.id : ''
        this.nameInStore = newVal.nameInStore ? newVal.nameInStore : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''

        this.inTransit = newVal.inTransit ? newVal.inTransit : ''
        this.reserved = newVal.reserved ? newVal.reserved : ''
        this.FBM = newVal.FBM ? newVal.FBM : ''
        this.FBA = newVal.FBA ? newVal.FBA : ''
        this.damaged = newVal.damaged ? newVal.damaged : ''
        this.shelves = newVal.shelves ? newVal.shelves : ''
        this.cellName = newVal.cellName ? newVal.cellName : ''

        this.archive = newVal.archive ? newVal.archive : ''
      },

    },

    computed: {
      checkShow() {
        return this.isMobileFunc() || (!this.isMobileFunc() && this.showFilter)
      },
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading, true)
      },

      changeUsersFilter(value) {
        this.userId = value.id
        this.userName = value.contacts[0].user_full_name
      },

      changeArchiveFilter(val) {
        this.archive = val.value
      },

    },
  }
</script>

<style scoped>

</style>
