<template>
  <!--v-if="$store.getters.getIsAdminRights === 'user'"-->
  <div>
    <ProductsEditUser
            class="product-edit-left"
            :product="product"
            :historyOfChanged="historyOfChanged"
            @changeCategory="changeCategoryProform"
            @updateProduct="updateProduct"
    />
  </div>

</template>

<script>
  import ProductsEditUser from "./ProductsEditUser/ProductsEditUser";
  import {ProformProduct} from "../../../../globalModels/ProformProduct";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  import {productMixins} from "../../../../../mixins/productMixins/productMixins";
  import {BLAINE_WAREHOUSE} from "../../../../../staticData/staticVariables";

  export default {
    name: "ProductsEdit",

    components: {
      ProductsEditUser,
    },

    mixins: [
      proformMixins,
      productMixins,
    ],

    data() {
      return {
        product: new ProformProduct,


        historyOfChanged: null,
      }
    },

    created() {
      let storageId = this.$route.query.storageId ? this.$route.query.storageId : BLAINE_WAREHOUSE.storage_id

      this.initializeProductProform().then(() => {
        this.$store.dispatch('getProducts', this.$route.params.id + `?storageId=${storageId}`).then(() => {

          let data = this.$store.getters.getProductsItem

          if (!data?.proformData?.proformOptions) {
            data.proformData.proform_category = ''
            data.proformData.proformOptions = {}
            data.proformData.proformOptions.handmade = '1'
            data.proformData.proformOptions.item_price = 0
            data.proformData.proformOptions.item_quantity = 0
          }

          this.product.setProductItem(data, null, this)

          if(data.productInstance?.activityUUID) {
            this.historyOfChanged = data.productInstance.activityUUID
          }

          this.initialProformSelectOptions({
            val: this.product.product.proformData.proformCategory,
            item: this.product
          })
        }).catch(error => this.createErrorLog(error))
      })
    },

    mounted() {

    },

    methods: {


    },

  }
</script>

<style scoped>
  @media (max-width: 992px) {
    .product-edit-left >>> .card-detail-left__bg--home {
      background-image: none;
    }
  }
</style>
