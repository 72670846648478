<template>
  <div class="count-circle">
    <span class="count-circle__item">
      {{value}}
    </span>
  </div>
</template>

<script>
  export default {
    name: "CountCircle",
    props:[
      'value'
    ],
  }
</script>

<style lang="scss">
  @import '../../../scss/colors';

  .count-circle{

    &__item{
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-weight: 500;
      font-size: 9px;
      line-height: 11px;
      color: white;
      background: $brown;
      cursor: pointer;
    }
  }

</style>