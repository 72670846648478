<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'product_ProductId',
                  'product_CheckPaymentsHistory',
                  'product_ProductsNow',
                  // 'product_Returns',
                  // 'product_TotalCount',
                  'product_InTransit',
                  'product_Reserv',
                  'product_FBM',
                  'product_Damaged',
                ])"></div>
      <CardLeftBlock
              v-if="Object.hasOwnProperty.call($store.getters.getProductsItem, 'productInstance')"
              :name="$t('product_ProductId.localization_value.value')"
              :value="'#' + $store.getters.getProductsItem.productInstance.id">
        <template slot="button">
          <div class="card-detail-left__date">
            {{ $store.getters.getProductsItem.productInstance.created_at | moment("DD MMM, YYYY") }}
          </div>
          <div v-if="isAdmin" class="card-detail-left__username">
            {{getUserName(productItem)}}
          </div>
          <div class="site-link brown text-decoration-underline mt-4">
            <div v-if="isAdmin && historyOfChanged"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('product', historyOfChanged)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>
        </template>
        <template slot="footer">
          <div class="block-table">
            <div class="block-table__name mt-4">
              {{$t('product_ProductsNow.localization_value.value')}}
            </div>
            <div class="block-table__row"
                 v-if="getProductCountInfo !== undefined"
            >
              <div class="block-table__col block-table__col--50 flex-row">
                <div class="block-table__label mr-2">{{$t('product_InTransit.localization_value.value')}}</div>
                <div class="block-table__content">
                  <span class="table-link">
                  {{ getProductCountInfo.transit }}
                  </span>
                </div>
              </div>
              <div class="block-table__col block-table__col--50 flex-row">
                <div class="block-table__label mr-2">{{$t('product_Reserv.localization_value.value')}}</div>
                <div class="block-table__content">
                  <span class="table-link">
                  {{ getProductCountInfo.reserve }}
                  </span>
                </div>
              </div>
              <div class="block-table__col block-table__col--50 flex-row">
                <div class="block-table__label mr-2">{{$t('product_FBM.localization_value.value')}}</div>
                <div class="block-table__content">
                  <span class="table-link">
                  {{ getProductCountInfo.count_in_storage.count_normal }}
                  </span>
                </div>
              </div>
              <div class="block-table__col block-table__col--50 flex-row">
                <div class="block-table__label mr-2">{{$t('product_Damaged.localization_value.value')}}</div>
                <div class="block-table__content">
                  <span class="table-link">
                  {{ getProductCountInfo.count_in_storage.count_damage }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>


    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">


            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_BackProducts',
                ])"></span>
                <router-link :to="$store.getters.GET_PATHS.products" class="order-create__head-back">
                  <LinkBack
                          :value="$t('product_BackProducts.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_Product',
                ])"></span>
              {{$t('product_Product.localization_value.value')}}
            </div>
            <div class="order-create__head-txt"
                 v-bind:class="{
                 'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
                 'mb-0': $store.getters.getIsAdminRights === 'admin'
                 }">
              <span class="admin-edit" @click="editTranslate([
                  'product_PleaseFillOut',
                ])"></span>
              {{$t('product_PleaseFillOut.localization_value.value')}}
            </div>

            <div class="order-create__head-steps"
                 v-if="$store.getters.getIsAdminRights === 'user'"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'product_ProductDetails',
                  'product_SenderInfo',
                  'product_Confirmation',
                ])"></div>
              <ProgressSteps
                      :options="progressStepsSetting"
                      :step="activeStep"
                      @changeStep="stepChange"
                      @nextPageHandler="nextPageHandler"
                      class="product-progress-steps"
              />
            </div>
          </div>
        </template>

        <template slot="body">
          <div v-if="activeStep == 1 || $store.getters.getIsAdminRights === 'admin'">
<!--            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">-->
<!--              <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100">-->
<!--                <UserSelect-->
<!--                        :serverError="serverError"-->
<!--                        :validationUser="productItem.validation.user"-->
<!--                        :validationTxtUser="productItem.validationTxt.user"-->
<!--                        :validationTranslateUser="productItem.validationTranslate.user"-->
<!--                        :userSelected="productItem.product.user"-->
<!--                        @changeUser="changeUser"-->
<!--                        :typeSelect="'users'"-->
<!--                        :userEmail="true"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->

            <div v-if="!isAdmin" class="order-create__title-horizontal"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate([
                  'product_Product',
                ])"></span>
              <TitleHorizontal
                      :value="$t('product_Product.localization_value.value') + '#1'"
                      :rightBtn="true"
              />
            </div>


            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_ProductName',
                ])"></span>
                <DefaultInput
                        :label="$t('product_ProductName.localization_value.value')"
                        :errorTxt="serverError ? productItem.validationTxt.productName : $t(`${productItem.validationTranslate.productName}.localization_value.value`)"
                        v-model="productItem.product.productName"
                        v-bind:class="{'ui-no-valid': productItem.validation.productName}"
                        :error="productItem.validation.productName"
                />
              </div>

              <div class="custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_NameStore',
                ])"></span>
                <DefaultInput
                    :label="$t('product_NameStore.localization_value.value')"
                    :errorTxt="serverError ? productItem.validationTxt.productNameFromShop : $t(`${productItem.validationTranslate.productNameFromShop}.localization_value.value`)"
                    v-model="productItem.product.productNameFromShop"
                    v-bind:class="{'ui-no-valid': productItem.validation.productNameFromShop}"
                    :error="productItem.validation.productNameFromShop"
                />
              </div>

              <div v-if="isAdmin" class="custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_IHaveUPCCode',
                ])"></span>
                <DefaultCheckbox
                    v-if="!isAdmin"
                    :label="$t('product_IHaveUPCCode.localization_value.value')"
                    class="mb-2"
                    :disabled="productItem.product.canUpdate === false"
                    v-model="product.product.isUPC"
                />
                <div v-bind:class="{'hide-upc': !product.product.isUPC && !isAdmin}">
                  <div class="mt-3"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_UPCCode',
                ])"></span>
                    <DefaultInput
                        :label="$t('product_UPCCode.localization_value.value')"
                        :errorTxt="serverError ? productItem.validationTxt.UPC : $t(`${productItem.validationTranslate.UPC}.localization_value.value`)"
                        v-bind:class="{'ui-no-valid': productItem.validation.UPC}"
                        :disabled="productItem.product.canUpdate === false && !isAdmin"
                        :error="productItem.validation.UPC"
                        :maxLength="'20'"
                        v-model="product.product.UPC"
                    />
                  </div>
                </div>
              </div>

              <div v-if="isAdmin" class="custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_SKU',
                ])"></span>
                  <DefaultInput
                      :label="$t('product_SKU.localization_value.value')"
                      :errorTxt="serverError ? productItem.validationTxt.SKU : $t(`${productItem.validationTranslate.SKU}.localization_value.value`)"
                      v-bind:class="{'ui-no-valid': productItem.validation.SKU}"
                      :disabled="productItem.product.canUpdate === false"
                      :error="productItem.validation.SKU"
                      v-model="product.product.SKU"
                  />
                </div>
              </div>
            </div>

            <ProductItemCreation
                    :product="product"
                    :serverError="serverError"
                    :canChangeHSCode="true"
                    :altVersion="true"
                    @changeCategory="({val, nameVal}) => $emit('changeCategory', {val, nameVal, item: product}, null, true)"
                    @changeProformSelectItem="changeProformSelectItem(product)"
                    v-bind:class="{'disabled-product':
                      $store.getters.getIsAdminRights === 'admin' && Object.keys(product.product.user).length === 0}"
            />


            <div class="order-create__total"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_TotalShippingCost',
                ])"></span>
              {{$t('product_TotalShippingCost.localization_value.value')}}
              <span> ${{product.product.price * product.product.itemsQuantity | number('0.00')}} </span>
            </div>

          </div>

          <div v-if="activeStep == 2 || $store.getters.getIsAdminRights === 'admin'">
            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_ProductName',
                ])"></span>
              {{$t('profile_Documents.localization_value.value')}} ({{$t('common_upTo.localization_value.value')}} 5)
            </div>

<!--            <div class="custom-row" v-if="productItem.product.maxCountFiles - Object.keys(productItem.product.downloadPhotos).length > 0">-->
            <div class="custom-row" v-if="productItem.product.Files.data.maxCountFiles - Object.keys(productItem.product.Files.data.downloadFiles).length > 0">
              <div class="custom-col">
<!--                          :parentFiles="productItem.product.photos"-->
                <DropZone class="drop-zone-bg"
                          :parentFiles="productItem.product.Files.data.files"
                          @changeImg="changeImg"
                          :maxSize="'3'"
                          :maxCount="productItem.product.Files.data.maxCountFiles - Object.keys(productItem.product.Files.data.downloadFiles).length"
                          :accept="'image/x-png,image/jpeg,'"
                />
              </div>
            </div>

            <div class="custom-row product-img-row" v-if="Object.keys(productItem.product.Files.data.downloadFiles).length > 0" :key="images">

              <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
                   v-for="(file, id, index) in productItem.product.Files.data.downloadFiles"
                   :key="index">
                <div class="site-document d-flex justify-content-center">
                  <div class="site-document__remove" @click="removeDocument(file.smallImageId, id)">
                    <CloseIcon/>
                  </div>
                  <div class="site-document__img">
                    <img :src="'data:image/png;base64,' + file.file"
                         @click="showSingle(file.bigImageId)" alt="img">
                  </div>
                </div>
              </div>

            </div>


            <MediaFiles
                :product="productItem"
            />



            <div v-if="!isAdmin" class="custom-row">
              <div class="custom-col mb-0">
                <div class="section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'common_Codes',
                ])"></span>
                  {{$t('common_Codes.localization_value.value')}}
                </div>
              </div>
              <div class="custom-col custom-col--66 custom-col--xl-50 custom-col--xs-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_SKU',
                ])"></span>
                <DefaultInput
                    :label="$t('product_SKU.localization_value.value')"
                    :errorTxt="serverError ? productItem.validationTxt.SKU : $t(`${productItem.validationTranslate.SKU}.localization_value.value`)"
                    v-bind:class="{'ui-no-valid': productItem.validation.SKU}"
                    :error="productItem.validation.SKU"
                    :disabled="productItem.product.canUpdate === false"
                    v-model="product.product.SKU"
                />
              </div>
            </div>

            <div v-if="!isAdmin" class="custom-row">
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_IHaveUPCCode',
                ])"></span>
                <DefaultCheckbox
                    :label="$t('product_IHaveUPCCode.localization_value.value')"
                    class="mb-2"
                    :disabled="productItem.product.canUpdate === false"
                    v-model="product.product.isUPC"
                />
                <div class="custom-row" v-bind:class="{'hide-upc': !product.product.isUPC}">
                  <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 mt-3 ml-4"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_UPCCode',
                ])"></span>
                    <DefaultInput
                        :label="$t('product_UPCCode.localization_value.value')"
                        :errorTxt="serverError ? productItem.validationTxt.UPC : $t(`${productItem.validationTranslate.UPC}.localization_value.value`)"
                        v-bind:class="{'ui-no-valid': productItem.validation.UPC}"
                        :error="productItem.validation.UPC"
                        :disabled="productItem.product.canUpdate === false"
                        :maxLength="'20'"
                        v-model="product.product.UPC"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div v-if="activeStep == 3 && $store.getters.getIsAdminRights === 'user'">
            <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_Product',
                ])"></span>
              <TitleHorizontal
                      :value="$t('product_Product.localization_value.value')"
                      :rightBtn="true"
                      :rightBtnType="'edit'"
                      :rightBtnText="'Edit'"
                      @rightBtnClick="activeStep = 1"
              />
            </div>

            <div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_NewProduct',
                ])"></span>
              {{$t('product_NewProduct.localization_value.value')}}
            </div>

            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <div class="confirm-product-card">
                  <div class="confirm-product-card__name word-break">
                    {{product.product.productName}}
                  </div>
                  <!--<div class="confirm-product-card__caption">-->
                  <!--Золота обручка-->
                  <!--</div>-->

                  <div class="confirm-product-card__items">
                    <div class="confirm-product-card__item" v-if="product.product.proformData.proformCategory !== ''">
                      <div class="confirm-product-card__item-name"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'proform_category',
                        ])"></div>
                        {{$t('proform_category.localization_value.value')}}
                      </div>
                      <div class="confirm-product-card__item-val">
                        {{product.product.proformData.proformCategory.translationStorage.en.name}}
                      </div>
                    </div>

                    <template v-if="localProformTypes = productItem.product.proformData.proformTypes">
                      <template v-for="(itemType, index) in $store.getters.getProformProductInitialize.proformTypes">
                        <div class="confirm-product-card__item" :key="index" v-if="isUkrainian">
                          <div class="confirm-product-card__item-name">
                            {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_Ua'].name
                            }}
                          </div>
                          <div class="confirm-product-card__item-val">
                            {{localProformTypes[itemType.id].typeValue !== '' ?
                            localProformTypes[itemType.id].typeValue.value.translationStorage.ua.name : ''}}
                          </div>
                        </div>
                        <div class="confirm-product-card__item" :key="index + '2'">
                          <div class="confirm-product-card__item-name">
                            {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name
                            }}
                          </div>
                          <div class="confirm-product-card__item-val">
                            {{localProformTypes[itemType.id].typeValue !== '' ?
                            localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : ''}}
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>

                  <div class="confirm-product-card__item"
                       v-if="product.product.HSCode !== ''"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                      'common_HSCode',
                    ])"></div>
                    <div class="confirm-product-card__item-name">
                      {{$t('common_HSCode.localization_value.value')}}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{product.product.HSCode}}
                    </div>
                  </div>

                  <div class="confirm-product-card__item"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['express_Price',])"></div>
                    <div class="confirm-product-card__item-name">
                      {{$t('express_Price.localization_value.value')}}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{product.product.price}}
                    </div>
                  </div>

                  <div class="confirm-product-card__item"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['express_Quantity',])"></div>
                    <div class="confirm-product-card__item-name">
                      {{$t('express_Quantity.localization_value.value')}}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{product.product.itemsQuantity}}
                    </div>
                  </div>

                  <div class="confirm-product-card__bottom" v-if="product.product.handMade"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                          'product_Handmade',
                        ])"></div>
                    <StatusIcoBtn :type="'handmade'" class="mr-2"/>
                    {{$t('product_Handmade.localization_value.value')}}
                  </div>
                </div>
              </div>
            </div>


            <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_SenderInfo',
                        ])"></div>
              <TitleHorizontal
                      :value="$t('common_SenderInfo.localization_value.value')"
                      :rightBtn="true"
                      :rightBtnType="'edit'"
                      :rightBtnText="'Edit'"
                      @rightBtnClick="activeStep = 2"
              />
            </div>

            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'profile_Documents',
                        ])"></div>
              {{$t('profile_Documents.localization_value.value')}}
            </div>
            <div class="custom-row" v-if="product.product.Files.data.files.length > 0">
              <template v-for="(item, index) in product.product.Files.data.files">
                <div class="custom-col mb-3"
                     v-if="product.product.Files.data.maxCountFiles > index && !item.maxSizeError"
                     :key="index">
                <span class="table-link">{{item.name}}</span>
              </div>
              </template>
            </div>

            <div class="custom-row mt-2">
              <div class="custom-col block-table__col--20 block-table__col--sm-50" v-if="product.product.isUPC"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'product_IHaveUPCCode',
                        ])"></div>
                <div class="section-label mb-2">
                 {{$t('product_IHaveUPCCode.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{product.product.UPC}}
                </div>
              </div>
              <div class="custom-col block-table__col--20 block-table__col--sm-50"
                   v-if="product.product.SKU.length > 0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'product_SKU',
                        ])"></div>
                <div class="section-label mb-2">
                  {{$t('product_SKU.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{product.product.SKU}}
                </div>
              </div>
            </div>

          </div>


<!--          <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">-->
<!--            <div class="custom-col">-->
<!--              <div class="order-create__toggle-block toggle-block small-size">-->
<!--                <div class="toggle-block__type">-->
<!--                  <div class="toggle-block__type-list">-->
<!--                    <div class="toggle-block__type-item mb-2">-->
<!--                      <div class="section-label m-0"-->
<!--                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                        <div class="admin-edit" @click="editTranslate([-->
<!--                          'common_WherePut',-->
<!--                        ])"></div>-->
<!--                        {{$t('common_WherePut.localization_value.value')}}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="toggle-block__type-item mb-2"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate([-->
<!--                          'product_ThisProductAssigned',-->
<!--                        ])"></div>-->
<!--                      <RadioDefault-->
<!--                              class="mw-180"-->
<!--                              :label="$t('product_ThisProductAssigned.localization_value.value')"-->
<!--                              name="radioField"-->
<!--                              @change.native="changeRadio(0)"-->
<!--                              :value="true"-->
<!--                      />-->
<!--                    </div>-->
<!--                    <div class="toggle-block__type-item mb-2"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate([-->
<!--                          'product_OtherBins',-->
<!--                        ])"></div>-->
<!--                      <RadioDefault-->
<!--                              :label="$t('product_OtherBins.localization_value.value')"-->
<!--                              name="radioField"-->
<!--                              @change.native="changeRadio(1)"-->
<!--                      />-->
<!--                    </div>-->
<!--                    <div class="toggle-block__type-item mb-2"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate([-->
<!--                          'product_MixedBins',-->
<!--                        ])"></div>-->
<!--                      <RadioDefault-->
<!--                              :label="$t('product_MixedBins.localization_value.value')"-->
<!--                              name="radioField"-->
<!--                              @change.native="changeRadio(2)"-->
<!--                      />-->
<!--                    </div>-->
<!--                    <div class="toggle-block__type-item mb-2"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate([-->
<!--                          'product_FreeBins',-->
<!--                        ])"></div>-->
<!--                      <RadioDefault-->
<!--                              :label="$t('product_FreeBins.localization_value.value')"-->
<!--                              name="radioField"-->
<!--                              @change.native="changeRadio(3)"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="toggle-block__details w-100">-->
<!--                  <div class="toggle-block__details-body">-->
<!--                    <div class="custom-row">-->
<!--                      <div class="custom-col">-->
<!--                        <div class="section-label mb-3"-->
<!--                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate([-->
<!--                          'product_Cell',-->
<!--                        ])"></div>-->
<!--                          {{$t('product_Cell.localization_value.value')}}-->
<!--                        </div>-->
<!--                        <DefaultSelect-->
<!--                                class="white"-->
<!--                                :errorTxt="'UPC'"-->
<!--                                :options="options"-->
<!--                                :label="$t('product_UPC.localization_value.value')"-->
<!--                                :selected="'F-3-45'"-->
<!--                        />-->
<!--                        <span class="fsz14 d-flex justify-content-end mt-2">3984 items</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="custom-row">-->
<!--                      <div class="custom-col custom-col&#45;&#45;50 mb-0"-->
<!--                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                        <div class="admin-edit" @click="editTranslate([-->
<!--                          'product_QtyAdd',-->
<!--                        ])"></div>-->
<!--                        <div class="section-label mb-3">-->
<!--                          {{$t('product_QtyAdd.localization_value.value')}}-->
<!--                        </div>-->
<!--                        <InputQuantity-->
<!--                                :value="valueQuantity01"-->
<!--                                v-model="valueQuantity01"-->
<!--                                @change="changeValueQuantity"-->
<!--                        />-->
<!--                      </div>-->
<!--                      <div class="custom-col custom-col&#45;&#45;50 mb-0"-->
<!--                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                        <div class="admin-edit" @click="editTranslate([-->
<!--                          'product_QtyRemove',-->
<!--                        ])"></div>-->
<!--                        <div class="section-label mb-3">-->
<!--                          {{$t('product_QtyRemove.localization_value.value')}}-->
<!--                        </div>-->
<!--                        <InputQuantity-->
<!--                                :value="valueQuantity02"-->
<!--                                v-model="valueQuantity02"-->
<!--                                @change="changeValueQuantity"-->
<!--                        />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->


        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_cancel',
                          'common_Previous',
                        ])"></div>
              <router-link :to="$store.getters.GET_PATHS.products"
                           class="site-link site-link--alt"
                           v-if="activeStep == 1"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
              <span class="site-link site-link--alt"
                    @click="prevPageHandler()"
                    v-if="activeStep != 1"
              >
                {{$t('common_Previous.localization_value.value')}}
              </span>
            </div>

            <!--<div class="order-create__footer-btn ml-auto">-->
            <!--<MainButton-->
            <!--class="order-create__footer-btn-i"-->
            <!--:value="'Next'"-->
            <!--/>-->
            <!--</div>-->

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_updateInDrafts',
                          'common_drafts',
                          'common_savingInDrafts',
                          'common_Next',
                          'common_update',
                        ])"></div>

              <MainButton
                      class="order-create__footer-btn-i secondary"
                      :value="$t('common_updateInDrafts.localization_value.value')"
                      :tooltip="true"
                      @click.native="$emit('updateProduct', true)"
                      v-if="activeStep === progressStepsSetting.length && $store.getters.getIsAdminRights === 'user' && productItem.product.canUpdate"
                      v-bind:class="{'disabled-btn' : $store.getters.getProductsBtn}"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{$t('common_drafts.localization_value.value')}}</b>
                  </p>
                  <p>
                    {{$t('common_savingInDrafts.localization_value.value')}}
                  </p>
                </template>
              </MainButton>


              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_Next.localization_value.value')"
                      @click.native="nextPageHandler()"
                      v-if="activeStep !== progressStepsSetting.length && $store.getters.getIsAdminRights === 'user'"
              />

              <MainButton
                      v-else
                      class="order-create__footer-btn-i"
                      :value="$t('common_update.localization_value.value')"
                      @click.native="$emit('updateProduct', false)"
                      v-bind:class="{'disabled-btn' : $store.getters.getProductsBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>

    <!-- all props & events -->
    <Lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
    ></Lightbox>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput.vue";
  import ProgressSteps from "../../../../../UI/progress/ProgressSteps/ProgressSteps.vue";
  import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal.vue";
  import DropZone from "../../../../../coreComponents/DropZone/DropZone";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ProductItemCreation from "../../../../../coreComponents/ProductItemCreation/ProductItemCreation";
  import StatusIcoBtn from "../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  // import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import CloseIcon from '../../../../../../../public/img/modal-group/close-icon.svg?inline'
  import Lightbox from 'vue-easy-lightbox'
  // import HistoryOfChanges from "../../../../../coreComponents/Popups/HistoryOfChangesPopup/HistoryOfChangesPopup";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import MediaFiles from "@/components/modules/ProductsModule/chunks/MediaFiles/MediaFiles";

  export default {
    name: "ProductsEditUser",

    components: {
      MediaFiles,
      // HistoryOfChanges,
      // UserSelect,
      StatusIcoBtn,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      DefaultInput,
      ProgressSteps,
      TitleHorizontal,
      DropZone,
      DefaultCheckbox,
      ProductItemCreation,
      CloseIcon,
      Lightbox,
    },

    mixins: [proformMixins],

    props: {
      product: Object,
      historyOfChanged: String,
    },

    watch: {
      product(newVal) {
        this.productItem = newVal
      }
    },

    computed: {
      getProductCountInfo() {
        return this.$store.getters.getProductsItem?.productInstance?.count
      },
    },

    data() {
      return {
        productItem: this.product,
        serverError: false,

        historyOfChangesPopup: false,

        valueQuantity01: 0,
        valueQuantity02: 0,
        options: [],

        progressStepsSetting: [
          this.$t('product_ProductDetails.localization_value.value'),
          this.$t('product_SenderInfo.localization_value.value'),
          this.$t('product_Confirmation.localization_value.value'),
        ],

        images: -1,

        activeStep: 1,

        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0 // default: 0
      }
    },

    methods: {
      changeValueQuantity(data) {
        this.valueQuantity = data
      },

      changeValueSum(data) {
        this.valueSum = data
      },

      stepChange(data) {
        this.activeStep = data + 1;
      },

      nextPageHandler(){
        let valid = true

        switch (this.activeStep) {
          case 1:
            valid = this.product.productCreateFirstValidation()
            if(this.$store.getters.getIsAdminRights === 'admin')
              valid = this.product.productCreateFirstValidationUser()
            break
          case 2:
            valid = this.product.productCreateSecondValidation()
            break
        }

        if(valid)
          if (this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
      },

      prevPageHandler(){
        if (this.activeStep > 1) this.activeStep = this.activeStep - 1
      },

      changeImg(files, maxSizeError) {
        this.product.product.Files.setFiles(files)

        this.product.product.Files.setFilesMaxSizeError(maxSizeError)
      },

      changeUser(user){
        this.product.setProductUser(user)
        this.product.setProductProformDataCategory('')
      },

      showSingle(id) {

        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        })

      },

      handleHide() {
        this.visible = false
      },

      show() {
        this.visible = true
      },

      removeDocument(id, item) {
        this.$store.dispatch('removeUserDocument', id).then(() => {
          let files = this.productItem.product.Files.data.downloadFiles
          delete files[item]
          this.images = item
        })
      },

      getUserName(item) {
        return item?.product.user?.contacts?.length > 0 ? item?.product.user?.contacts[0]?.user_full_name : ''
      }

    }
  }
</script>

<style lang="scss">

  .hide-upc {
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps {
    max-width: 625px;
  }

  .card-detail-left__username {
    margin-top: 10px;
    font-weight: 500;
  }

</style>
