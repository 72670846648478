<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup statistic-popup"
  >
    <template slot="header">
      Anna Bugrak
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="statistic-popup__price">
          $ 3 495.34
        </div>
        <div class="block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50">
              <div class="block-table__label">Phone</div>
              <div class="block-table__content">Lot: 3785829049</div>
            </div>
            <div class="block-table__col block-table__col--50">
              <div class="block-table__label">Email</div>
              <div class="block-table__content">mrsnoopythefig@gmai.com</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";

  export default {
    name: "StatisticPopup",
    components: {
      Modal,

    },

    data() {
      return {}
    },

    methods: {}
  }
</script>

<style scoped lang="scss">
  @import "../../../../../scss/colors";

  .statistic-popup {
    &__price{
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 16px;
      color: $black;
    }

    .modal-component__inner{
      max-width: 484px;
    }

    .modal-component__header{
      margin-bottom: 5px;
    }
  }
</style>