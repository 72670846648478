<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'breadcrumbs_NewProduct',
                ])"></div>
      <CardLeftBlock>
        <template slot="button">
          <h2 class="mt-0">{{$t('breadcrumbs_NewProduct.localization_value.value')}}</h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_BackProducts',
                ])"></span>
                <router-link :to="$store.getters.GET_PATHS.products" class="order-create__head-back">
                  <LinkBack
                          :value="$t('product_BackProducts.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_Product',
                ])"></span>
              {{$t('product_Product.localization_value.value')}}
            </div>
            <div class="order-create__head-txt"
                 v-bind:class="{
                 'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
                 'mb-0': $store.getters.getIsAdminRights === 'admin'
                 }">
              <span class="admin-edit" @click="editTranslate([
                  'product_PleaseFillOut',
                ])"></span>
              {{$t('product_PleaseFillOut.localization_value.value')}}
            </div>

            <div class="order-create__head-steps"
                 v-if="$store.getters.getIsAdminRights === 'user'"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'product_ProductDetails','product_SenderInfo','product_Confirmation',
                ])"></div>
              <ProgressSteps
                      :options="progressStepsSetting"
                      :step="activeStep"
                      @changeStep="stepChange"
                      @nextPageHandler="nextPageHandler"
                      class="product-progress-steps"
              />
            </div>
          </div>
        </template>

        <template slot="body">
          <div v-if="activeStep == 1 || $store.getters.getIsAdminRights === 'admin'">

            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :serverError="serverError"
                        :validationUser="productItem.validation.user"
                        :validationTxtUser="productItem.validationTxt.user"
                        :validationTranslateUser="productItem.validationTranslate.user"
                        :userSelected="productItem.product.user"
                        @changeUser="changeUser"
                        :typeSelect="'users'"
                        :userEmail="true"
                />
              </div>
            </div>

            <div v-if="!isAdmin" class="order-create__title-horizontal"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate([
                  'product_Product',
                ])"></span>
              <TitleHorizontal
                      :value="$t('product_Product.localization_value.value') + '#1'"
                  :rightBtn="true"
              />
            </div>


            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_ProductName',
                ])"></span>
                <DefaultInput
                        :label="$t('product_ProductName.localization_value.value')"
                        :errorTxt="serverError ? productItem.validationTxt.productName : $t(`${productItem.validationTranslate.productName}.localization_value.value`)"
                        v-model="productItem.product.productName"
                        v-bind:class="{'ui-no-valid': productItem.validation.productName}"
                        :error="productItem.validation.productName"
                />
              </div>

              <div class="custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_NameStore',
                ])"></span>
                <DefaultInput
                    :label="$t('product_NameStore.localization_value.value')"
                    :errorTxt="serverError ? productItem.validationTxt.productNameFromShop : $t(`${productItem.validationTranslate.productNameFromShop}.localization_value.value`)"
                    v-model="productItem.product.productNameFromShop"
                    v-bind:class="{'ui-no-valid': productItem.validation.productNameFromShop}"
                    :error="productItem.validation.productNameFromShop"
                />
              </div>

              <div v-if="isAdmin" class="custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_IHaveUPCCode',
                ])"></span>
                <DefaultCheckbox
                    v-if="!isAdmin"
                    :label="$t('product_IHaveUPCCode.localization_value.value')"
                    class="mb-2"
                    v-model="product.product.isUPC"
                />
                <div v-bind:class="{'hide-upc': !product.product.isUPC && !isAdmin}">
                  <div class="mt-3"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_UPCCode',
                ])"></span>
                    <DefaultInput
                        :label="$t('product_UPCCode.localization_value.value')"
                        :errorTxt="serverError ? productItem.validationTxt.UPC : $t(`${productItem.validationTranslate.UPC}.localization_value.value`)"
                        v-bind:class="{'ui-no-valid': productItem.validation.UPC}"
                        :error="productItem.validation.UPC"
                        :maxLength="'20'"
                        v-model="product.product.UPC"
                    />
                  </div>
                </div>
              </div>

              <div v-if="isAdmin" class="custom-col custom-col--33 custom-col--md-100 d-flex direction-column jc-flex-end">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_SKU',
                ])"></span>
                  <DefaultInput
                      :label="$t('product_SKU.localization_value.value')"
                      :errorTxt="serverError ? productItem.validationTxt.SKU : $t(`${productItem.validationTranslate.SKU}.localization_value.value`)"
                      v-bind:class="{'ui-no-valid': productItem.validation.SKU}"
                      :error="productItem.validation.SKU"
                      v-model="product.product.SKU"
                  />
                </div>
              </div>

            </div>
            <ProductItemCreation
                :product="product"
                :canChangeHSCode="true"
                :serverError="serverError"
                :altVersion="true"
                @changeCategory="({val, nameVal}) => $emit('changeCategory', {val, nameVal, item: product}, null, true)"
                @changeProformSelectItem="changeProformSelectItem(product)"
                v-bind:class="{'disabled-product':
                  $store.getters.getIsAdminRights === 'admin' && Object.keys(product.product.user).length === 0}"
            />


            <div class="order-create__total">
              <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'product_TotalShippingCost',
                ])"></div>
                {{$t('product_TotalShippingCost.localization_value.value')}}
              </div>
              <span> ${{product.product.price * product.product.itemsQuantity | number('0.00')}} </span>
            </div>

          </div>

          <div v-if="activeStep == 2 || $store.getters.getIsAdminRights === 'admin'">
            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_ProductName',
                ])"></span>
              {{$t('profile_Documents.localization_value.value')}} ({{$t('common_upTo.localization_value.value')}} 5)
            </div>

            <div class="custom-row">
              <div class="custom-col">
                <DropZone class="drop-zone-bg"
                          :parentFiles="productItem.product.Files.data.files"
                          @changeImg="changeImg"
                          :maxSize="'3'"
                          :maxCount="productItem.product.Files.data.maxCountFiles"
                          :accept="'image/x-png,image/jpeg,'"
                />
              </div>
            </div>

            <MediaFiles
                :product="productItem"
            />

            <div v-if="!isAdmin" class="custom-row">
              <div class="custom-col mb-0">
                <div class="section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'common_Codes',
                ])"></span>
                  {{$t('common_Codes.localization_value.value')}}
                </div>
              </div>
              <div class="custom-col custom-col--66 custom-col--xl-50 custom-col--xs-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_SKU',
                ])"></span>
                <DefaultInput
                    :label="$t('product_SKU.localization_value.value')"
                    :errorTxt="serverError ? productItem.validationTxt.SKU : $t(`${productItem.validationTranslate.SKU}.localization_value.value`)"
                    v-bind:class="{'ui-no-valid': productItem.validation.SKU}"
                    :error="productItem.validation.SKU"
                    v-model="product.product.SKU"
                />
              </div>
            </div>

            <div v-if="!isAdmin" class="custom-row">
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_IHaveUPCCode',
                ])"></span>
                <DefaultCheckbox
                    :label="$t('product_IHaveUPCCode.localization_value.value')"
                    class="mb-2"
                    v-model="product.product.isUPC"
                />
                <div class="custom-row" v-bind:class="{'hide-upc': !product.product.isUPC}">
                  <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 mt-3 ml-4"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_UPCCode',
                ])"></span>
                    <DefaultInput
                        :label="$t('product_UPCCode.localization_value.value')"
                        :errorTxt="serverError ? productItem.validationTxt.UPC : $t(`${productItem.validationTranslate.UPC}.localization_value.value`)"
                        v-bind:class="{'ui-no-valid': productItem.validation.UPC}"
                        :error="productItem.validation.UPC"
                        :maxLength="'20'"
                        v-model="product.product.UPC"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div v-if="activeStep == 3 && $store.getters.getIsAdminRights === 'user'">
            <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_Product',
                ])"></span>
              <TitleHorizontal
                      :value="$t('product_Product.localization_value.value')"
                      :rightBtn="true"
                      :rightBtnType="'edit'"
                      :rightBtnText="'Edit'"
                      @rightBtnClick="activeStep = 1"
              />
            </div>

            <div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_NewProduct',
                ])"></span>
              {{$t('product_NewProduct.localization_value.value')}}
            </div>

            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <div class="confirm-product-card">
                  <div class="confirm-product-card__name word-break">
                    {{product.product.productName}}
                  </div>

                  <div class="confirm-product-card__items">
                    <div class="confirm-product-card__item" v-if="product.product.proformData.proformCategory !== ''">
                      <div class="confirm-product-card__item-name"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'proform_category',
                        ])"></div>
                        {{$t('proform_category.localization_value.value')}}
                      </div>
                      <div class="confirm-product-card__item-val">
                        {{product.product.proformData.proformCategory.translationStorage.en.name}}
                      </div>
                    </div>

                    <template v-if="localProformTypes = productItem.product.proformData.proformTypes">
                      <template  v-for="(itemType, index) in $store.getters.getProformProductInitialize.proformTypes">
                        <div class="confirm-product-card__item" :key="index" v-if="isUkrainian">
                          <div class="confirm-product-card__item-name">
                            {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_Ua'].name }}
                          </div>
                          <div class="confirm-product-card__item-val">
                            {{localProformTypes[itemType.id].typeValue !== '' ? localProformTypes[itemType.id].typeValue.value.translationStorage.ua.name : ''}}
                          </div>
                        </div>
                        <div class="confirm-product-card__item" :key="index + '2'">
                          <div class="confirm-product-card__item-name">
                            {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name }}
                          </div>
                          <div class="confirm-product-card__item-val">
                            {{localProformTypes[itemType.id].typeValue !== '' ? localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : ''}}
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>

                  <div class="confirm-product-card__item"
                       v-if="product.product.HSCode !== ''"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                      'common_HSCode',
                    ])"></div>
                    <div class="confirm-product-card__item-name">
                      {{$t('common_HSCode.localization_value.value')}}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{product.product.HSCode}}
                    </div>
                  </div>

                  <div class="confirm-product-card__item"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['express_Price',])"></div>
                    <div class="confirm-product-card__item-name">
                      {{$t('express_Price.localization_value.value')}}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{product.product.price}}
                    </div>
                  </div>

                  <div class="confirm-product-card__item"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['express_Quantity',])"></div>
                    <div class="confirm-product-card__item-name">
                      {{$t('express_Quantity.localization_value.value')}}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{product.product.itemsQuantity}}
                    </div>
                  </div>

                  <div class="confirm-product-card__bottom" v-if="product.product.handMade"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                          'product_Handmade',
                        ])"></div>
                    <StatusIcoBtn :type="'handmade'" class="mr-2"/>
                    {{$t('product_Handmade.localization_value.value')}}
                  </div>
                </div>
              </div>
            </div>


            <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_SenderInfo',
                        ])"></div>
              <TitleHorizontal
                      :value="$t('common_SenderInfo.localization_value.value')"
                      :rightBtn="true"
                      :rightBtnType="'edit'"
                      :rightBtnText="'Edit'"
                      @rightBtnClick="activeStep = 2"
              />
            </div>

            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'profile_Documents',
                        ])"></div>
              {{$t('profile_Documents.localization_value.value')}}
            </div>
            <div class="custom-row" v-if="product.product.Files.data.files.length > 0">
              <template v-for="(item, index) in product.product.Files.data.files">
                <div class="custom-col mb-3"
                     v-if="product.product.Files.data.maxCountFiles > index && !item.maxSizeError"
                     :key="index">
                  <span class="table-link">{{item.name}}</span>
                </div>
              </template>
            </div>

            <div class="custom-row mt-2">
              <div class="custom-col block-table__col--20 block-table__col--sm-50" v-if="product.product.isUPC"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'product_IHaveUPCCode',
                        ])"></div>
                <div class="section-label mb-2">
                 {{$t('product_IHaveUPCCode.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{product.product.UPC}}
                </div>
              </div>
              <div class="custom-col block-table__col--20 block-table__col--sm-50"
                   v-if="product.product.SKU.length > 0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'product_SKU',
                        ])"></div>
                <div class="section-label mb-2">
                  {{$t('product_SKU.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{product.product.SKU}}
                </div>
              </div>
            </div>

          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_cancel',
                          'common_Previous',
                        ])"></div>
              <router-link :to="$store.getters.GET_PATHS.products"
                           class="site-link site-link--alt"
                           v-if="activeStep == 1"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
              <span class="site-link site-link--alt"
                    @click="prevPageHandler()"
                    v-if="activeStep != 1"
              >
                {{$t('common_Previous.localization_value.value')}}
              </span>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_SaveInDrafts',
                          'common_drafts',
                          'common_savingInDrafts',
                          'common_Next',
                          'common_create',
                        ])"></div>
              <MainButton
                      class="order-create__footer-btn-i secondary"
                      :value="$t('common_SaveInDrafts.localization_value.value')"
                      :tooltip="true"
                      @click.native="$emit('createProduct', true)"
                      v-if="activeStep === progressStepsSetting.length && $store.getters.getIsAdminRights === 'user'"
                      v-bind:class="{'disabled-btn' : $store.getters.getProductsBtn}"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{$t('common_drafts.localization_value.value')}}</b>
                  </p>
                  <p>
                    {{$t('common_savingInDrafts.localization_value.value')}}
                  </p>
                </template>
              </MainButton>


              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_Next.localization_value.value')"
                      @click.native="nextPageHandler()"
                      v-if="activeStep !== progressStepsSetting.length && $store.getters.getIsAdminRights === 'user'"
              />

              <MainButton
                      v-else
                      class="order-create__footer-btn-i"
                      :value="$t('common_create.localization_value.value')"
                      @click.native="$emit('createProduct', false)"
                      v-bind:class="{'disabled-btn' : $store.getters.getProductsBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput.vue";
  // import InputSum from "../../../../../UI/inputs/InputSum/InputSum.vue";
  import ProgressSteps from "../../../../../UI/progress/ProgressSteps/ProgressSteps.vue";
  import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal.vue";
  import DropZone from "../../../../../coreComponents/DropZone/DropZone";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ProductItemCreation from "../../../../../coreComponents/ProductItemCreation/ProductItemCreation";
  import StatusIcoBtn from "../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  // import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  // import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import InputQuantity from "../../../../../UI/inputs/InputQuantity/InputQuantity";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import MediaFiles from "@/components/modules/ProductsModule/chunks/MediaFiles/MediaFiles";
  // import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import AddToProfileIco from '../../../../../../../public/img/UI-group/add-to-profile-ico.svg?inline'

  export default {
    name: "ProductsCreationUser",

    components: {
      MediaFiles,
      UserSelect,
      // InputQuantity,
      // DefaultSelect,
      // RadioDefault,
      StatusIcoBtn,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      DefaultInput,
      // InputSum,
      ProgressSteps,
      TitleHorizontal,
      DropZone,
      DefaultCheckbox,
      // DefaultSelect,
      // AddToProfileIco,
      ProductItemCreation,
    },

    mixins: [proformMixins],

    props: {
      product: Object,
    },

    watch: {
      product(newVal) {
        this.productItem = newVal
      }
    },

    data() {
      return{
        productItem: this.product,
        serverError: false,

        valueQuantity01: 0,
        valueQuantity02: 0,
        options: [],

        progressStepsSetting: [
          this.$t('product_ProductDetails.localization_value.value'),
          this.$t('product_SenderInfo.localization_value.value'),
          this.$t('product_Confirmation.localization_value.value'),
        ],

        activeStep: 1,

      }
    },

    methods:{
      changeValueQuantity(data){
        this.valueQuantity = data
      },

      changeValueSum(data){
        this.valueSum = data
      },

      stepChange (data){
        this.activeStep = data + 1;
      },

      nextPageHandler(){
        let valid = true

        switch (this.activeStep) {
          case 1:
            valid = this.product.productCreateFirstValidation()
            if(this.$store.getters.getIsAdminRights === 'admin')
              valid = this.product.productCreateFirstValidationUser()
            break
          case 2:
            valid = this.product.productCreateSecondValidation()
            break
        }

        if(valid)
          if ( this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
      },

      prevPageHandler(){
        if ( this.activeStep > 1) this.activeStep = this.activeStep - 1
      },

      changeImg(files, maxSizeError) {
        this.product.product.Files.setFiles(files)

        this.product.product.Files.setFilesMaxSizeError(maxSizeError)
      },

      changeUser(user){
        this.product.setProductUser(user)
        this.product.setProductProformDataCategory('')
      },

    }
  }
</script>

<style lang="scss">

  .hide-upc{
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps{
    max-width: 625px;
  }

</style>
