
<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getProductsLoading === true}">

        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <!--              <span class="admin-edit" @click="editTranslate([item.label])"></span>-->
              <template>
                {{item.label}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block light-bg-block--mobile-wrap">
      <div class="btn-left-block">
        <DefaultCheckbox
                :label="$t('warehouseManagement_selectAll.localization_value.value')"
                @input="(val) => $emit('selectAll', val)"
        />
      </div>
      <div class="btn-right-block btn-right-block--mobile-wrap">
        <div class="content-top-line-btn ml-2" style="position: relative;"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_import',
        ])"></span>
          <TableHeadButton
              :value="$t('common_import.localization_value.value')"
              :ico="'import'"
              @click.native="changeImportPopup(true)"
          />
          <div class="likeTooltipBtn">
            <TableHeadButton
                :value="''"
                :ico="'question'"
                class="likeTooltipBtn__item"
                @click.native="downloadExample"
            />
          </div>
        </div>
        <div class="content-top-line-btn ml-2"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_termalPrint',
        ])"></span>
          <TableHeadButton
                  :value="$t('common_termalPrint.localization_value.value')"
                  :ico="'print'"
                  @click.native="$emit('massThermalPrint')"
          />
        </div>

        <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'common_AddNew',
          ])"></span>
          <router-link :to="$store.getters.GET_PATHS.productsCreate">
            <MainButton class="btn-fit-content ml-2"
                        :value="$t('common_AddNew.localization_value.value')"
                        :ico="'ico'"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus" />
              </template>
            </MainButton>
          </router-link>
        </div>
      </div>
    </div>

    <ImportPopup
        v-if="isModalImportPopup"
        @close="changeImportPopup(false)"
        @reloadTable="$emit('reloadTable')"
    />
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TableHeadButton from "../../../../../../coreComponents/TableComponents/TableHeadButton/TableHeadButton";
  import ImportPopup from "../../../../popups/ImportPopup/ImportPopup";


  export default {
    name: "ProductsHead",
    components: {
      ImportPopup,
      MainButton,
      ToggleFilterButton,
      PlusIconSVG,
      DefaultCheckbox,
      TableHeadButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {

    },

    created() {

    },

    data() {
      return {
        isModalImportPopup: false,
      }
    },



    methods: {


      changeTab(val) {
        this.$emit('changeTab', val)
      },

      changeImportPopup(val){
        this.isModalImportPopup = val
      },

      downloadExample() {
        this.$store.dispatch('getImportExampleXLSXProducts').then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: 'application/xlsx'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Product_import_example.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },

    }
  }
</script>

<style scoped>

.likeTooltipBtn{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-20px, -8px);
}

@media (max-width: 1220px){
  .likeTooltipBtn{
    transform: translate(-8px, -13px);
  }

}

.likeTooltipBtn__item{
  padding: 0;
  width: 20px;
  margin-top: -18px;
  margin-left: 4px;
}

  @media (max-width: 1220px){
    .print-btn__btn {
      padding-right: 16px;
      padding-left: 0;
    }
  }

</style>
