
<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup comments-popup"
  >
    <template slot="header">
      Comments
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="comments-block">
          <div class="comments-block__list">
            <div class="comments-block__item">
              <div class="comments-block__time">
                23 Aug, 2020 at 12:34pm
              </div>
              <div class="comments-block__text">
                Доброго дня! Ми отримали консолiдацiю за трек номером 392873852957, але
                через вiдсутнiсть створеной консолiдацii вiдправити не зможемо. Створiть
                консолiдацiю та повiдомте склад у вайбер або чат про це.<br>
                <br>
                Гарного дня,
                <br>
                <br>
                SkladUSA<br>
                Rostislav <br>
                Warehouse Manager<br>
              </div>
            </div>
            <div class="comments-block__item">
              <div class="comments-block__time">
                23 Aug, 2020 at 12:34pm
              </div>
              <div class="comments-block__text">
                Доброго дня! Ми отримали консолiдацiю за трек номером 392873852957, але
                через вiдсутнiсть створеной консолiдацii вiдправити не зможемо. Створiть
                консолiдацiю та повiдомте склад у вайбер або чат про це.<br>
                <br>
                Гарного дня,
                <br>
                <br>
                SkladUSA<br>
                Rostislav <br>
                Warehouse Manager<br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";

  export default {
    name: "CommentsPopup",
    components: {
      Modal,

    },

    data() {
      return {

      }
    },

    methods: {

    }
  }
</script>

<style lang="scss">

  .comments-popup {

    .modal-component__inner {
      max-width: 612px;
    }
  }
</style>
