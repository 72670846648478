<template>
  <div class="frontend">
    <!--<ProductsCreationAdmin-->
        <!--v-if="$store.getters.getIsAdminRights === 'admin'"-->
    <!--/>-->
    <!--v-if="$store.getters.getIsAdminRights === 'user'"-->
    <ProductsCreationUser
        :product="product"
        @changeCategory="changeCategoryProform"
        @createProduct="createProduct"
    />
  </div>
</template>

<script>
  // import ProductsCreationAdmin from "./ProductsCreationAdmin/ProductsCreationAdmin";
  import ProductsCreationUser from "./ProductsCreationUser/ProductsCreationUser.vue";
  import {ProformProduct} from "../../../../globalModels/ProformProduct";
  // import {PROFORM_CATEGORY_SAVED, PROFORM_CATEGORY_SEARCH, PROFORM_TYPE_NAMING} from "../../../../../staticVariables";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";

  export default {
    name: "ProductsCreation",

    components:{
      // ProductsCreationAdmin,
      ProductsCreationUser,
    },

    mixins: [proformMixins],

    data() {
      return {
        product: new ProformProduct,
      }
    },

    created() {
      this.initializeProductProform()
    },

    mounted() {

    },

    methods: {

      // changeCategoryProfiles({val, nameVal}) {
      //   this.product.unsetProductProformType()
      //   this.product.setProductProformDataCategory(val)
      //
      //   if(val.id === PROFORM_CATEGORY_SEARCH){
      //     return
      //   }
      //
      //   if(val.id === PROFORM_CATEGORY_SAVED){
      //     this.$store.dispatch('getSavedProform', this.$store.getters.GET_COMMON_AUTHORIZED.user.id)
      //     return
      //   }
      //
      //   let filter = '?filter[ProformAttachmentProformCategoryId]=' + val.id
      //
      //   this.$store.dispatch('fetchProformProductCategory', filter).then((response) => {
      //     let data = response.data.data
      //
      //     let localProformTypes = this.product.product.proformData.proformTypes
      //
      //     data.map(type => {
      //       Object.keys(type).map(item => {
      //
      //         if(type[item].length > 0){
      //
      //           // if click on search naming type
      //           if(nameVal !== '' && item == PROFORM_TYPE_NAMING){
      //             delete nameVal.category
      //             localProformTypes[item].typeValue = nameVal
      //           } else {
      //             localProformTypes[item].typeValue = type[item][0]
      //           }
      //         }
      //
      //         localProformTypes[item].typeOptions = type[item]
      //       })
      //     })
      //
      //     this.product.setProductProformTypes(localProformTypes)
      //   })
      // },

      createProduct(draft = false) {
        if(this.$store.getters.getIsAdminRights === 'admin'){
          if(!this.product.productCreateFirstValidationUser()) return
          if(!this.product.productCreateFirstValidation()) return
          if(!this.product.productCreateSecondValidation()) return
        }


        let data = this.product.prepareProduct(
          draft,
          this.$store.getters.getIsAdminRights === 'user' ?
            this.$store.getters.GET_COMMON_AUTHORIZED.user.id : '',
            this.isAdmin
        )
        this.$store.dispatch('createProducts', data).then((response) => {

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.createFiles(response.data.data.id)
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        }).catch(error => this.createErrorLog(error))
      },

      createFiles(productId) {
        // let data = {}
        let reader = new FormData();
        this.product.product.Files.data.files.map((item, index) => {
          if(!this._.has(item, 'maxCount') && !item.maxSizeError){
            reader.append("file"+(index+1), item.file);
          }
        })
        reader.append("product_id", productId);

        this.$store.dispatch('addPhotoToProduct', reader).then((response) => {

          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$router.push(this.$store.getters.GET_PATHS.products)

          } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            alert('novalid')
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }

        }).catch(error => this.createErrorLog(error))
      },
    },

  }
</script>

<style scoped>

</style>
