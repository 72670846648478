<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup lastFiveIp-popup"
  >
    <template slot="header">
      Last 5 IP for Anna Bugrak
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="site-table-wrap table-small">
          <table class="site-table min-w-initial">
            <thead>
            <tr>
              <th>#</th>
              <th>IP</th>
              <th>City</th>
              <th>Region</th>
              <th>Country</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>1</td>
              <td>91.124.55.394</td>
              <td>Kharkiv</td>
              <td>Kharkiv</td>
              <td>Ukraine</td>
            </tr>
            <tr>
              <td>2</td>
              <td>91.124.55.394</td>
              <td>Kyiv</td>
              <td>Kyiv</td>
              <td>Ukraine</td>
            </tr>
            <tr>
              <td>3</td>
              <td>91.124.55.394</td>
              <td>Kharkiv</td>
              <td>Kharkiv</td>
              <td>Ukraine</td>
            </tr>
            <tr>
              <td>4</td>
              <td>91.124.55.394</td>
              <td>Kyiv</td>
              <td>Kyiv</td>
              <td>Ukraine</td>
            </tr>
            <tr>
              <td>5</td>
              <td>91.124.55.394</td>
              <td>Kharkiv</td>
              <td>Kharkiv</td>
              <td>Ukraine</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";

  export default {
    name: "LastFiveIPPopup",
    components: {
      Modal,

    },

    data() {
      return {}
    },

    methods: {}
  }
</script>

<style lang="scss">
  .lastFiveIp-popup {

    .modal-component__inner {
      max-width: 612px;
    }
  }
</style>
