<template>
  <div class="fragment">
    <template v-if="$store.getters.getProducts.length > 0 && !$store.getters.getProductsLoading">
      <div class="site-table-wrap products-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'product_id',
          'product_Created',
          'importO_user',
          'product_UPC',
          'product_SKU',
          'product_ProductName',
          'product_NameStore',
          'product_EtsyEbay',
          'product_InTransit',
          'product_Reserv',
          'product_ResFBM',
          'product_ResFBA',
          'product_FBM',
          'product_Damaged',
          'common_manage',
        ])"></span>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>
              {{$t('product_id.localization_value.value')}}
              <SortButton :sortParams="sortParams" :defaultSort="'DESC'" :sortName="'sortId'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>{{$t('product_Created.localization_value.value')}}</th>
            <th>{{$t('product_UPC.localization_value.value')}}</th>
            <th>{{$t('product_SKU.localization_value.value')}}</th>
            <th>{{$t('product_ProductName.localization_value.value')}}</th>
            <th>{{$t('product_NameStore.localization_value.value')}}</th>
            <th class="white-space-line">
              {{$t('product_InTransit.localization_value.value')}}
              <SortButton :sortParams="sortParams" :sortName="'sortTransit'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>
              {{$t('product_Reserv.localization_value.value')}}
              <SortButton :sortParams="sortParams" :sortName="'sortReserv'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>
              {{$t('product_FBM.localization_value.value')}}
              <SortButton :sortParams="sortParams" :sortName="'sortNormal'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>
              {{$t('product_Damaged.localization_value.value')}}
              <SortButton :sortParams="sortParams" :sortName="'sortDamaged'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getProducts"
              :key="index">
            <td>
              <DefaultCheckbox class="empty-label"
                               :selectedNow="selectedNow"
                               :dataValue="item.id"/>
            </td>
            <td>
              <span class="table-link btn-style"
                      @click="changeInfoIDPopup(true, item.id)">
                #{{item.id}}
              </span>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td>
              <TooltipTextHelper
                  class="comment-row"
                  :full-text-width="130"
                  :text="item['upc_code']"
                  :paragraph="item['upc_code']"
                  :styleClass="'white-space-normal style-ellipsis'"
              />
            </td>
            <td>
              <TooltipTextHelper
                  class="comment-row"
                  :full-text-width="130"
                  :text="item['sku_code']"
                  :paragraph="item['sku_code']"
                  :styleClass="'white-space-normal style-ellipsis'"
              />
            </td>
            <td>
<!--              <TooltipTextHelper-->
<!--                  :full-text-width="300"-->
<!--                  :text="item['name']"-->
<!--                  :paragraph="item['name']"-->
<!--                  :styleClass="'white-space-normal style-ellipsis'"-->
<!--              />-->
              <div style="max-width: 300px" class="word-break white-space-normal">{{item.name}}</div>
            </td>

            <td>
              <TooltipTextHelper
                  :full-text-width="130"
                  :text="item['name_from_shop']"
                  :paragraph="item['name_from_shop']"
                  :styleClass="'white-space-normal style-ellipsis'"
              />
              <!--              <span class="ellipsis">{{item.name}}</span>-->
            </td>

            <td class="text-center">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=transit&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.transit'"
                />
              </router-link>

            </td>
            <td class="text-center">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=reserve&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.reserve'"
                />
              </router-link>
            </td>
            <td class="text-center">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=all&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.count_in_storage.count_normal'"
                />
              </router-link>
            </td>
            <td class="text-center">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=damage&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.count_in_storage.count_damage'"
                />
              </router-link>
            </td>
            <td>
              <div class="table-right table-manage-list table-manage-list--small">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                            v-if="item.in_draft"
                            :value="$t('common_EditDraft.localization_value.value')"
                            :type="'edit'"
                            :link="$store.getters.GET_PATHS.products + '/' + item.id + '?storageId=' + getStorageId"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                            v-if="!item.in_draft"
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :link="$store.getters.GET_PATHS.products + '/' + item.id + '?storageId=' + getStorageId"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        v-if="item.archived === 0"
                        :value="$t('common_toArchive.localization_value.value')"
                        :type="'sendTo'"
                        @click.native="toggleArchive(item.id, true)"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        v-if="item.archived === 1"
                        :value="$t('common_fromArchive.localization_value.value')"
                        :type="'sendTo'"
                        @click.native="toggleArchive(item.id, false)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getProducts"
                 :key="index"
            >
              <ProductsTableMobile
                      :item="item"
                      :getStorageId="getStorageId"
                      @isModalProductsToWarehouse="isModalProductsToWarehouse = true"
                      @toggleArchive="toggleArchive(item.id)"
                      @changeInfoIDPopup="changeInfoIDPopup(true, item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getProductsCommonList.next_page_url !== null && $store.getters.getProducts.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextProductsPage}"
                    :count="$store.getters.getProductsCommonList.total - $store.getters.getProductsForPage * $store.getters.getProductsCommonList.current_page < $store.getters.getProductsForPage ?
                  $store.getters.getProductsCommonList.total - $store.getters.getProductsForPage * $store.getters.getProductsCommonList.current_page:
                  $store.getters.getProductsForPage"
            />
            <ExportBtn
                @downloadFiles="type => $emit('downloadFiles', type)"
                    class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getProductsLoading === false && $store.getters.getProducts.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>


    <InfoIDPopup
            v-if="isModalInfoIDPopup"
            @close="changeInfoIDPopup(false)"
    />

  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import ProductsToWarehouse from "../../../../popups/ProductsToWarehouse/ProductsToWarehouse";
  import ProductsTableMobile from "./ProductsTableMobile/ProductsTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import InfoIDPopup from "../../../../popups/InfoIDPopup/InfoIDPopup";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import SortButton from "@/components/UI/buttons/SortButton/SortButton";
  import {productMixins} from "@/mixins/productMixins/productMixins";


  export default {
    name: "ProductsTable",

    components: {
      SortButton,
      TooltipTextHelper,
      ValueHelper,
      InfoIDPopup,
      NoResult,
      ProductsTableMobile,
      // MainButton,
      // DefaultInput,
      ShowMore,
      ExportBtn,
      DefaultCheckbox,
      LinkButton,
      ManagerButton,
      ProductsToWarehouse,
    },

    props: {
      sortParams: Object,
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    mixins: [
      mixinDetictingMobile,
      productMixins,
    ],

    computed: {
      getStorageId() {
        return this._.find(this.navTabs, {active: true})?.id
      },
    },

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        isModalProductsToWarehouse: false,

        isModalInfoIDPopup: '',
      }
    },

    methods: {
      closePopup(){
        this.isModalProductsToWarehouse = false
      },

      changeInfoIDPopup(val, id = false){
        let storage = this.getStorageId
        if(id) {
          this.$store.dispatch('getProducts', id + `?storageId=${storage}`).then(() => {
            this.isModalInfoIDPopup = val
          })
          return
        }

        this.isModalInfoIDPopup = val
      },
    }

  }
</script>

<style lang="scss" scoped>

  .products-table .table-manage-list .manager-btn{
    width: 186px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis{
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }



</style>
