<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      #{{$store.getters.getProductsItem.productInstance.id}}
    </template>
    <template slot="body">
      <div class="transaction-info__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_orderDetails',
          'product_UPC',
          'product_SKU',
          'product_ProductName',
          'product_NameStore',
          'common_Statistics',
          'product_InTransit',
          'product_FBM',
          'product_Reserv',
          'product_FBA',
          'product_Damaged',
        ])"></span>

        <div class="transaction-info__name" v-if="$store.getters.getIsAdminRights === 'admin'">
          {{$store.getters.getProductsItem.productInstance.user.contacts[0].user_full_name}}
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            {{$t('common_orderDetails.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('product_UPC.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getProductsItem.productInstance.upc_code}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('product_SKU.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getProductsItem.productInstance.sku_code}}
              </div>
            </div>
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('product_ProductName.localization_value.value')}}
              </div>
              <div class="transaction-info__txt word-break">
                {{$store.getters.getProductsItem.productInstance.name}}
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('common_HSCode.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getProductsItem.productInstance.hs_code}}
              </div>
            </div>
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('product_NameStore.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="$store.getters.getProductsItem.productInstance.name_from_shop"
                />
              </div>
            </div>
          </div>


        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            {{$t('common_Statistics.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('product_InTransit.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ getProductCountInfo.transit }}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('product_Reserv.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ getProductCountInfo.reserve }}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('product_FBM.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ getProductCountInfo.count_in_storage.count_normal }}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('product_Damaged.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ getProductCountInfo.count_in_storage.count_damage }}
              </div>
            </div>

          </div>

          <div class="custom-row product-img-row" v-if="Object.keys(product.product.Files.data.downloadFiles).length > 0" :key="images">
            <div class="custom-col mt-3 mb-0">
            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'profile_Documents',
                ])"></span>
              {{$t('profile_Documents.localization_value.value')}}
            </div>
          </div>

            <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
                 v-for="(file, index) in product.product.Files.data.downloadFiles"
                 :key="index">
              <div class="site-document d-flex justify-content-center">
                <div class="site-document__img">
                  <img :src="'data:image/png;base64,' + file.file"
                       @click="showSingle(file.bigImageId)" alt="img">
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- all props & events -->
        <Lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
        ></Lightbox>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import {ProformProduct} from "@/components/globalModels/ProformProduct";
  import Lightbox from "vue-easy-lightbox";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";


  export default {
    name: "InfoIDPopup",
    components: {
      ValueHelper,
      Modal,
      Lightbox,
    },

    computed: {
      getProductCountInfo() {
        return this.$store.getters.getProductsItem?.productInstance?.count
      },
    },

    data() {
      return {
        product: new ProformProduct,

        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0 // default: 0
      }
    },

    mounted() {
      let data = this.$store.getters.getProductsItem
      this.product.setProductItem(data, null, this)
    },

    methods: {

      showSingle(id) {

        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        })

      },

      handleHide() {
        this.visible = false
      },

      show() {
        this.visible = true
      },
    }

  }

</script>

<style lang="scss">

</style>
