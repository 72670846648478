<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup goods-relocation"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['product_goodsRelocationPopupTitle'])"></div>
        {{ $t(`product_goodsRelocationPopupTitle.localization_value.value`) }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col">
            {{storage.label}}
          </div>
        </div>
        <div class="custom-row">
          <div class="custom-col" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['product_goodsRelocationCellFrom'])"></div>
            <DefaultSelect
                :options="cellsFrom"
                :label="$t(`product_goodsRelocationCellFrom.localization_value.value`)"
                :optionsLabel="'combo_name'"
                :selected="cellFrom"
                :clearable="false"
                v-bind:class="{'ui-no-valid': validation.cellFrom}"
                :errorTxt="$t(`${validationTranslate.cellFrom}.localization_value.value`)"
                :error="validation.cellFrom"
                @change="val => setCellFrom(val)"
            />
          </div>
        </div>

        <div v-if="cellFrom !== null" class="custom-row">
          <div class="custom-col" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['product_goodsRelocationShowProducts'])"></div>
            <router-link
                class="d-inline-block"
                :to="$store.getters.GET_PATHS.financeReportCells + '?id=' + cellFrom.id"
                :target="'_blank'"
            >
              <MainButton
                  class="secondary btn-fit-content"
                  :value="$t(`product_goodsRelocationShowProducts.localization_value.value`)"
              />
            </router-link>
          </div>
        </div>

        <div class="custom-row">
          <div class="custom-col" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['product_goodsRelocationCellTo'])"></div>
            <DefaultSelect
                :options="cellsTo"
                :label="$t(`product_goodsRelocationCellTo.localization_value.value`)"
                :optionsLabel="'combo_name'"
                :selected="cellTo"
                :clearable="false"
                v-bind:class="{'ui-no-valid': validation.cellTo}"
                :errorTxt="$t(`${validationTranslate.cellTo}.localization_value.value`)"
                :error="validation.cellTo"
                @change="val => setCellTo(val)"
            />
          </div>
        </div>

        <div v-if="cellTo !== null" class="custom-row">
          <div class="custom-col" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['product_goodsRelocationShowProducts'])"></div>
            <router-link
                class="d-inline-block"
                :to="$store.getters.GET_PATHS.financeReportCells + '?id=' + cellTo.id"
                :target="'_blank'"
            >
              <MainButton
                  class="secondary btn-fit-content"
                  :value="$t(`product_goodsRelocationShowProducts.localization_value.value`)"
              />
            </router-link>
          </div>
        </div>

        <div class="custom-row">
          <div class="custom-col"></div>
        </div>

      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap">
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
        {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            class="custom-popup__btn-i "
            v-bind:class="{'disabled-btn' : $store.getters.getWarehouseStorageBtn}"
            :value="$t('common_save.localization_value.value')"
            @click.native="relocateGoods"
        />
      </div>
    </template>
  </modal>
</template>

<script>

import Modal from "../../../commonModals/Modal";
import MainButton from "../../../UI/buttons/MainButton/MainButton";
import DefaultSelect from "../../../UI/selectiones/DefaultSelect/DefaultSelect";


export default {
  name: "RelocateGoodsPopup",

  components: {
    DefaultSelect,
    Modal,
    MainButton,
  },

  mixins: [
  ],

  props: {
    storage: Object,
  },

  data() {
    return {
      initialCells: [],
      cellsFrom: [],
      cellsTo: [],

      cellFrom: null,
      cellTo: null,

      validation: {
        cellFrom: false,
        cellTo: false,
      },

      validationTranslate: {
        cellFrom: 'error_required',
        cellTo: 'error_required',
      },
    }
  },

  computed: {

  },

  mounted() {
    this.$store.dispatch('getWarehouseStorageCellWithFilterPublic', `?filter[byStorageId]=${this.storage.id}&notShowItems=1&limit=10000`).then(response => {
      this.initialCells = this.getRespData(response)
      this.cellsFrom = this.initialCells.filter(item => {
        return item?.items && item?.items.length > 0
      })
    })
  },

  methods: {
    relocateGoods() {
      let valid = true
      if (!this.cellFrom) {
        valid = false
        this.validation.cellFrom = true
      }
      else {
        this.validation.cellFrom = false
      }
      if (!this.cellTo) {
        valid = false
        this.validation.cellTo = true
      }
      else {
        this.validation.cellTo = false
      }

      if (!valid) return

      let data = {
        'from_cell_id': this.cellFrom?.id,
        'to_cell_id': this.cellTo?.id,
      }

      this.$store.dispatch('relocateWarehouseStorageCell', data).then(response => {
        switch (response.status) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            this.$emit('successRelocate')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    setCellFrom(val) {
      this.cellFrom = val
      if (val?.mixed) {
        this.cellsTo = this.initialCells.filter(item => {
          return item?.mixed && item?.id !== val?.id
        })
      }
      else {
        this.cellsTo = this.initialCells.filter(item => {
          return item?.id !== val?.id
        })
      }

      if(this.cellTo?.id === this.cellFrom?.id) {
        this.cellTo = null
      }
    },

    setCellTo(val) {
      this.cellTo = val
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
