<template>
  <div class="fragment">
    <template v-if="$store.getters.getProducts.length > 0 && !$store.getters.getProductsLoading">
      <div class="site-table-wrap small-table products-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'product_id',
          'product_Created',
          'importO_user',
          'product_UPC',
          'product_SKU',
          'product_ProductName',
          'product_NameStore',
          'product_EtsyEbay',
          'product_InTransit',
          'product_Reserv',
          'product_FBM',
          'product_Shelves',
          'product_Damaged',
          'common_manage',
        ])"></span>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>
              {{$t('product_id.localization_value.value')}}
              <SortButton :sortParams="sortParams" :defaultSort="'DESC'" :sortName="'sortId'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>{{$t('product_Created.localization_value.value')}}</th>
            <th>{{$t('importO_user.localization_value.value')}}</th>
            <th>{{$t('product_UPC.localization_value.value')}}</th>
            <th>{{$t('product_SKU.localization_value.value')}}</th>
            <th>{{$t('product_ProductName.localization_value.value')}}</th>
            <th>{{$t('product_NameStore.localization_value.value')}}</th>
            <!--<th class="hide-1600">{{$t('product_EtsyEbay.localization_value.value')}}</th>-->
            <th class="white-space-line">
              {{$t('product_InTransit.localization_value.value')}}
              <SortButton :sortParams="sortParams" :sortName="'sortTransit'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>
              {{$t('product_Reserv.localization_value.value')}}
              <SortButton :sortParams="sortParams" :sortName="'sortReserv'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>
              {{$t('product_FBM.localization_value.value')}}
              <SortButton :sortParams="sortParams" :sortName="'sortNormal'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>
              {{$t('product_Damaged.localization_value.value')}}
              <SortButton :sortParams="sortParams" :sortName="'sortDamaged'" @changeSort="data => $emit('changeSort', data)"/>
            </th>
            <th>{{$t('product_Shelves.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getProducts"
              :key="index">
            <td>
              <DefaultCheckbox class="empty-label"
                               :selectedNow="selectedNow"
                               :dataValue="item.id"
              />
            </td>
            <td>
              <span class="table-link btn-style"
                    @click="changeInfoIDPopup(true, item.id)"
              >
                #{{item.id}}
              </span>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td>
              <TableUserColumn
                      :item="item"
              />
            </td>
            <td>
              <TooltipTextHelper
                  class="comment-row"
                  :full-text-width="130"
                  :text="item['upc_code']"
                  :paragraph="item['upc_code']"
                  :styleClass="'white-space-normal style-ellipsis'"
              />
            </td>
            <td>
              <TooltipTextHelper
                  class="comment-row"
                  :full-text-width="130"
                  :text="item['sku_code']"
                  :paragraph="item['sku_code']"
                  :styleClass="'white-space-normal style-ellipsis'"
              />
            </td>
            <td>
<!--              <TooltipTextHelper-->
<!--                  :full-text-width="130"-->
<!--                  :text="item['name']"-->
<!--                  :paragraph="item['name']"-->
<!--                  :styleClass="'white-space-normal style-ellipsis'"-->
<!--              />-->
              <div style="max-width: 300px" class="word-break white-space-normal">{{item.name}}</div>
            </td>
            <td>
              <TooltipTextHelper
                  :full-text-width="130"
                  :text="item['name_from_shop']"
                  :paragraph="item['name_from_shop']"
                  :styleClass="'white-space-normal style-ellipsis'"
              />
              <!--              <span class="ellipsis">{{item.name}}</span>-->
            </td>
            <!--<td class="hide-1600">-->
              <!--Vanity Fair Rsa...-->
            <!--</td>-->
            <td class="text-center">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=transit&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                        :value="item"
                        :deep="'count.transit'"
                />
              </router-link>
            </td>
            <td class="text-center">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=reserve&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.reserve'"
                />
              </router-link>
            </td>
            <td class="text-center">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=all&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.count_in_storage.count_normal'"
                />
              </router-link>
            </td>
            <td class="text-center">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=damage&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.count_in_storage.count_damage'"
                />
              </router-link>
            </td>
            <td>
              <CellsBlock
                  v-if="getItemsWithCells(item.items_in_storage)"
                  :cells="getItemsWithCells(item.items_in_storage)"
              />
            </td>
            <td>
              <div class="table-right table-manage-list table-manage-list--small">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :link="$store.getters.GET_PATHS.products + '/' + item.id + '?storageId=' + getStorageId"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        v-if="isAdmin"
                            :value="$t('common_delete.localization_value.value')"
                            :type="'delete'"
                            @click.native="removeProduct(item.id)"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        :value="$t('product_SendTo.localization_value.value')"
                        :type="'sendTo'"
                        @click.native="moveProductCell = true; moveProduct = item"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        v-if="item.archived === 0"
                        :value="$t('common_toArchive.localization_value.value')"
                        :type="'sendTo'"
                        @click.native="toggleArchive(item.id, true)"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        v-if="item.archived === 1"
                        :value="$t('common_fromArchive.localization_value.value')"
                        :type="'sendTo'"
                        @click.native="toggleArchive(item.id, false)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getProducts"
                 :key="index"
            >
              <ProductsTableMobile
                      :item="item"
                      :getStorageId="getStorageId"
                      :selectedNow="selectedNow"
                      @removeProduct="removeProduct(item.id)"
                      @toggleArchive="toggleArchive(item.id)"
                      @moveProductCell="moveProductCell = true; moveProduct = item"
                      @changeInfoIDPopup="changeInfoIDPopup(true, item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getProductsCommonList.next_page_url !== null && $store.getters.getProducts.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextProductsPage}"
                    :count="$store.getters.getProductsCommonList.total - $store.getters.getProductsForPage < $store.getters.getProductsForPage ?
                  $store.getters.getProductsCommonList.total - $store.getters.getProductsForPage:
                  $store.getters.getProductsForPage"
            />
            <ExportBtn
                @downloadFiles="type => $emit('downloadFiles', type)"
                    class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getProductsLoading === false && $store.getters.getProducts.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->

    <CommentsPopup
        v-if="isModalCommentsPopup"
        @close="changeCommentsPopup(false)"
    />

    <SendEmailPopup
        v-if="isModalSendEmailPopup"
        @close="changeSendEmailPopup(false)"
    />

    <LastFiveIPPopup
        v-if="isModalLastFiveIPPopup"
        @close="changeLastFiveIPPopup(false)"
    />

    <StatisticPopup
        v-if="isModalStatisticPopup"
        @close="changeStatisticPopup(false)"
    />

    <InfoIDPopup
        v-if="isModalInfoIDPopup"
        @close="changeInfoIDPopup(false)"
    />

    <MoveProductCell
        v-if="moveProductCell"
        :product="moveProduct"
        :navTabs="navTabs"
        @close="moveProductCell = false"
        @moveSuccess="moveProductCell = false; $emit('reload')"
    />

  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import CommentsPopup from "../../../../popups/CommentsPopup/CommentsPopup";
  import SendEmailPopup from "../../../../popups/SendEmailPopup/SendEmailPopup";
  import LastFiveIPPopup from "../../../../popups/LastFiveIPPopup/LastFiveIPPopup";
  import StatisticPopup from "../../../../popups/StatisticPopup/StatisticPopup";
  import InfoIDPopup from "../../../../popups/InfoIDPopup/InfoIDPopup";
  import ProductsTableMobile from "../ProductsTable/ProductsTableMobile/ProductsTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import MoveProductCell from "@/components/modules/ProductsModule/chunks/MoveProductCell/MoveProductCell";
  // import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  // import CountCircle from "@/components/coreComponents/CountCircle/CountCircle";
  import CellsBlock
    from "@/components/modules/ProductsModule/components/ProductsTable/ProductsTableAdmin/ProductsTable/CellsBlock/CellsBlock";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import SortButton from "@/components/UI/buttons/SortButton/SortButton";
  import {productMixins} from "@/mixins/productMixins/productMixins";


  export default {
    name: "ProductsTable",

    components: {
      SortButton,
      TooltipTextHelper,
      CellsBlock,
      // CountCircle,
      // TooltipTextHelper,
      MoveProductCell,
      ValueHelper,
      TableUserColumn,
      NoResult,
      ProductsTableMobile,
      ShowMore,
      ExportBtn,
      DefaultCheckbox,
      LinkButton,
      ManagerButton,
      CommentsPopup,
      SendEmailPopup,
      LastFiveIPPopup,
      StatisticPopup,
      InfoIDPopup,
    },

    props: {
      sortParams: Object,
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    mixins: [
      mixinDetictingMobile,
      productMixins,
    ],

    computed: {
      getStorageId() {
        return this._.find(this.navTabs, {active: true})?.id
      },
    },

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalCommentsPopup: '',
        isModalSendEmailPopup: '',
        isModalLastFiveIPPopup: '',
        isModalStatisticPopup: '',
        isModalInfoIDPopup: '',

        moveProductCell: false,
        moveProduct: null,

      }
    },

    methods: {
      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      changeCommentsPopup(val){
        this.isModalCommentsPopup = val
      },

      changeSendEmailPopup(val){
        this.isModalSendEmailPopup = val
      },

      changeLastFiveIPPopup(val){
        this.isModalLastFiveIPPopup = val
      },

      changeStatisticPopup(val){
        this.isModalStatisticPopup = val
      },

      changeInfoIDPopup(val, id = false){
        let storage = this.getStorageId
        if(id) {
          this.$store.dispatch('getProducts', id + `?storageId=${storage}`).then(() => {
            this.isModalInfoIDPopup = val
          })
          return
        }

        this.isModalInfoIDPopup = val
      },

      cantDeleteProduct(){
        let text = {
          title: 'Ooops',
          txt: 'Sorry, you cannot uninstall this product',
          no: 'Ok'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      getItemsWithCells(storage) {
        let array = []
        storage.map(item => {
          if(item.cell)
            array.push(item)
        })
        return array
      },

      removeProduct(id){
        let text = {
          title: 'product_DeleteProduct',
          txt: 'product_DeleteProductSure',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteProducts', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                let products = this.$store.getters.getProducts
                products.map((item, index) => {
                  if(item.id === id) {
                    products.splice(index, 1)
                  }
                })
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    }

  }
</script>

<style lang="scss" scoped>

  .site-table thead th,
  .site-table tbody td{
    padding-right: 5px;
    padding-left: 5px;
  }

  .products-table .table-manage-list .manager-btn{
    width: 165px;
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .products-table .site-table thead th:last-child,
  .products-table .site-table tbody td:last-child {
    padding-right: 0;
  }

  .products-table .ellipsis{
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }

  @media(max-width: 1600px){
    .hide-1600{
      display: none;
    }
  }

</style>
