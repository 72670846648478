<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox
            :selectedNow="selectedNow"
            :dataValue="item.id"
            class="mt-1"/>

        <div class="table-card__item-number"
             @click="$emit('changeInfoIDPopup')"
        >
          #{{item.id}}
        </div>

      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>

      <div class="table-card__item-row custom-row mt-3" v-if="!show">
        <div class="table-card__item-col custom-col mb-2" v-if="isAdmin">
          <div class="table-card__item-label">
            {{$t('common_user.localization_value.value')}}
          </div>
          <div class="table-card__item-info">
            <TableUserColumn
                :item="item"
                :mobile="true"
            />
          </div>
        </div>
        <div class="table-card__item-col custom-col custom-col--50 mb-2">
          <div class="table-card__item-label">
            {{$t('product_UPC.localization_value.value')}}
          </div>
          <div class="table-card__item-info">
            {{item.upc_code}}
          </div>
        </div>
        <div class="table-card__item-col custom-col custom-col--50 mb-2">
          <div class="table-card__item-label">
            {{$t('product_SKU.localization_value.value')}}
          </div>
          <div class="table-card__item-info">
            {{item.sku_code}}
          </div>
        </div>
        <div class="table-card__item-col custom-col custom-col--50 mb-0">
          <div class="table-card__item-label">
            {{$t('product_FBM.localization_value.value')}}
          </div>
          <div class="table-card__item-info">
            <router-link
                v-if="item.user"
                class="table-link"
                :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=all&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                :target="'_blank'"
            >
              <ValueHelper
                  :value="item"
                  :deep="'count.count_in_storage.count_normal'"
              />
            </router-link>
          </div>
        </div>
        <div class="table-card__item-col custom-col custom-col--50 mb-2">
          <div class="table-card__item-label">
<!--            {{$t('product_SKU.localization_value.value')}}-->
          </div>
          <div class="table-card__item-info">
            <div class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('product_SendTo.localization_value.value')"
                  :type="'sendTo'"
                  @click.native="$emit('moveProductCell')"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('product_id.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              #{{item.id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('product_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('product_UPC.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.upc_code}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('product_SKU.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.sku_code}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('product_ProductName.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.name}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('product_NameStore.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.name_from_shop}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('product_InTransit.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=transit&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.transit'"
                />
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('product_Reserv.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=reserve&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.reserve'"
                />
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('product_FBM.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=all&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.count_in_storage.count_normal'"
                />
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('product_Damaged.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link
                  v-if="item.user"
                  class="table-link"
                  :to="$store.getters.GET_PATHS.warehouseProductsReport +
                `?type=damage&userId=${item.user.id}&userName=${getUserContactDataFromUser(item.user).user_full_name}&productId=${item.id}&productName=${item.name}&fromProdTable=true&storage=${getStorageId}`"
                  :target="'_blank'"
              >
                <ValueHelper
                    :value="item"
                    :deep="'count.count_in_storage.count_damage'"
                />
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('product_Shelves.localization_value.value')}}
            </div>
            <div class="table-card__item-info">

              <CellsBlock
                  v-if="getItemsWithCells(item.items_in_storage)"
                  :cells="getItemsWithCells(item.items_in_storage)"
              />

<!--              <div class="table-row" v-if="item.items_in_storage.length > 0">-->
<!--                <div class="transactions-row mr-2"-->
<!--                >-->
<!--                  <template v-for="(cell, index) in item.items_in_storage">-->
<!--                           <span class="table-link fsz11 btn-style tran-0 mr-1 text-decoration-none"-->
<!--                                 :key="index"-->
<!--                                 v-if="index !== 0 && cell.cell"-->
<!--                           >{{ cell.cell.combo_name }} <span-->
<!--                               v-if="index < item.items_in_storage.length - 1">,</span> </span>-->
<!--                  </template>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('product_Damaged.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <span class="table-link">
                <ValueHelper
                        :value="item"
                        :deep="'count.count_in_storage.count_damage'"
                />
              </span>
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.products + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('product_SendTo.localization_value.value')"
                :type="'sendTo'"
                @click.native="$emit('moveProductCell')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="isAdmin">
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removeProduct')"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                v-if="item.archived === 0"
                :value="$t('common_toArchive.localization_value.value')"
                :type="'sendTo'"
                @click.native="$emit('toggleArchive')"
            />
            <LinkButton
                v-if="item.archived === 1"
                :value="$t('common_fromArchive.localization_value.value')"
                :type="'sendTo'"
                @click.native="$emit('toggleArchive')"
            />
          </div>
          <!--<div class="table-card__item-btn-i">-->
          <!--<UkrposhtaBtn/>-->
          <!--</div>-->
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import CellsBlock
    from "@/components/modules/ProductsModule/components/ProductsTable/ProductsTableAdmin/ProductsTable/CellsBlock/CellsBlock";
  export default {
    name: "ProductsTableMobile",
    components: {CellsBlock, ValueHelper, TableUserColumn, LinkButton, DefaultCheckbox},

    props: {
      item: Object,
      getStorageId: Number,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {
      getItemsWithCells(storage) {
        let array = []
        storage.map(item => {
          if(item.cell)
            array.push(item)
        })
        return array
      },
    },

  }
</script>

<style scoped>

</style>
