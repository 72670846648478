import {validation} from "../../services/validation";
import {
  PROFORM_CATEGORY_SAVED,
  PROFORM_CATEGORY_SEARCH,
  PROFORM_TYPE_MATERIAL,
  PROFORM_TYPE_NAMING
} from "../../staticData/staticVariables";
import {Files} from "@/components/globalModels/Files";

let _ = require('lodash');

export function ProformProduct() {

  this.product = {
    productName: '',
    productNameFromShop: '',
    productObject: '',
    proformData: {
      proformCategory: '',
      proformTypes: {
      },
    },
    itemsQuantity: 1,
    price: 0,
    handMade: true,
    photos:[],
    SKU: '',
    isUPC: false,
    UPC: '',

    nameForLabel: '',

    HSCode: '',

    userId: '',
    user: {},
    maxCountFiles: 5,

    Files: new Files({
      maxSizeFiles: 3,
      maxCountFiles: 5
    }),

    proformEntityId: '',

    system: '0',
    canUpdate: true,

    proformArchived: false,

    medias: [
      {media_url: ''},
      {media_url: ''},
      {media_url: ''},
      {media_url: ''},
      {media_url: ''},
    ],

    serviceProduct: false,
  },

  this.copyProfile = '',

  this.validation = {
    productName: false,
    productNameFromShop: false,
    proformCategory: false,
    proformNaming: false,
    proformMaterial: false,
    HSCode: false,
    SKU: false,
    UPC: false,
    user: false,
    itemsQuantity: false,
    price: false,
    proformArchived: false,
  },

  this.validationTranslate = {
    productName: '',
    productNameFromShop: '',
    proformCategory: '',
    proformNaming: '',
    proformMaterial: '',
    HSCode: '',
    SKU: '',
    UPC: '',
    user: '',
    itemsQuantity: '',
    price: '',
    proformArchived: '',
  },

  this.validationTxt = {
    productName: false,
    productNameFromShop: false,
    proformCategory: false,
    proformNaming: false,
    proformMaterial: false,
    HSCode: false,
    SKU: false,
    UPC: false,
    user: false,
    itemsQuantity: false,
    price: false,
    proformArchived: false,
  }
}





/********************************** SETTERS ****************************************/

ProformProduct.prototype.setProductName = function (val) {
  this.product.productName = val
}

ProformProduct.prototype.setProductNameFromShop = function (val) {
  this.product.productNameFromShop = val
}

ProformProduct.prototype.setProductObject = function (val) {
  this.product.productObject = val
}

ProformProduct.prototype.setProductProformData = function (val) {
  this.product.proformData = val
}


ProformProduct.prototype.setProductProformDataCategory = function (val) {
  this.product.proformData.proformCategory = val
}

ProformProduct.prototype.setProductProformTypes = function (val) {
  this.product.proformData.proformTypes = val
}

ProformProduct.prototype.setProductProformOptions = function (val) {
  this.product.proformData.proformTypes.typeOptions = val
}

ProformProduct.prototype.setNameForLabel = function (val) {
  this.product.nameForLabel = val
}

ProformProduct.prototype.setMedias = function (val) {
  this.product.medias = val
}

ProformProduct.prototype.setServiceProduct = function (val) {
  this.product.serviceProduct = val
}

ProformProduct.prototype.unsetProductProformType = function () {
  Object.keys(this.product.proformData.proformTypes).map(item => {
    this.product.proformData.proformTypes[item].typeOptions = [{}]

    this.product.proformData.proformTypes[item].typeValue = ''

  })
}

ProformProduct.prototype.setProductItem = function (productItem, entityId, that) {
  let product = this.product
  let instance = productItem.productInstance

  this.setProformEntityId(entityId ? entityId : '')

  this.setProductObject(instance)

  this.setProductUser(instance?.user ? instance?.user : '')
  this.setProductName(instance?.name)
  this.setProductNameFromShop(instance?.name_from_shop)
  this.setProductSKU(instance?.sku_code ? instance.sku_code : '')

  this.setProductIsUPC(instance?.upc_code !== null)
  this.setProductUPC(instance?.upc_code ? instance.upc_code : '')

  this.setProductHandMade(productItem?.proformData?.proformOptions?.handmade === '1')
  this.setProductPrice(productItem?.proformData?.proformOptions?.item_price || 0)
  // this.setProductItemsQuantity(productItem?.proformData?.proformOptions?.item_quantity || 0)
  this.setProductItemsQuantity(1)

  this.product.Files.setDownloadFiles(productItem.files ? productItem.files : {})

  if(instance?.medias && instance.medias.length > 0) {
    let medias = []
    instance.medias.forEach((item) => {
      medias.push({media_url: item.media_url})
    })
    this.setMedias(medias)
  }

  this.setServiceProduct(instance.service_product)

  if (instance?.canUpdate === false) {
    this.product.canUpdate = false
  }

  /**
   * skip data if archived && not admin
   */
  if (productItem?.proformData.proformOptions.archived === 1 && !that.isAdmin) {
    product.proformArchived = true
    this.setHSCode('')
    this.setProductProformDataCategory('')
    Object.keys(product.proformData.proformTypes).map(item => {
      if(productItem.proformData.values[item])
        product.proformData.proformTypes[item].typeValue = ''
    })
    return
  } else {
    product.proformArchived = false
  }

  if(productItem?.proformData?.proform_category) {
    this.setProductProformDataCategory(productItem?.proformData?.proform_category)
  }

  this.setHSCode(instance?.hs_code ? instance.hs_code : '')

  /**
   * if not has values
   */
  if(!productItem.proformData?.values)
    return

  Object.keys(product.proformData.proformTypes).map(item => {
    if(productItem.proformData.values[item])
      product.proformData.proformTypes[item].typeValue = productItem.proformData.values[item][0]
  })

  // /** 'system' for ORDERS INBOUND**/
  // if(productItem['productInstance']['system'] === '1'){
  //   this.setProductUser(productItem['productInstance']['system'])
  // }

}

ProformProduct.prototype.setProductIsUPC = function (val) {
  this.product.isUPC = val
}

ProformProduct.prototype.setProductItemsQuantity = function (val) {
  this.product.itemsQuantity = val
}

ProformProduct.prototype.setProductPrice = function (val) {
  this.product.price = val
}

ProformProduct.prototype.setProductHandMade = function (val) {
  this.product.handMade = val
}

ProformProduct.prototype.setProductSKU = function (val) {
  this.product.SKU = val
}

ProformProduct.prototype.setHSCode = function (val) {
  this.product.HSCode = val
}

ProformProduct.prototype.setProductUPC = function (val) {
  this.product.UPC = val
}

ProformProduct.prototype.setProductUserId = function (val) {
  this.product.userId = val
}

ProformProduct.prototype.setProformEntityId = function (val) {
  this.product.proformEntityId = val
}

ProformProduct.prototype.setProductUser = function (val) {
  if(val === ''){
    this.product.user = {}
    this.product.userId = ''
  } else {
    this.product.user = val
    this.product.userId = val.id
  }
}


ProformProduct.prototype.setOrderProformItem = function (proformItem, entityId, isAdmin) {
  let product = this.product

  console.log(123123123);
  console.log(proformItem);

  this.setProformEntityId(entityId ? entityId : '')
  this.setProductHandMade(proformItem.proformOptions.handmade === '1')
  this.setProductPrice(proformItem.proformOptions.item_price)
  this.setProductItemsQuantity(proformItem.proformOptions.item_quantity)

  if(proformItem.proformOptions?.label_full_name) {
    this.setNameForLabel(proformItem.proformOptions.label_full_name)
  }

  /**
   * skip data if archived && not admin
   */
  if (proformItem.proformOptions.archived === 1 && !isAdmin) {
    product.proformArchived = true
    this.setHSCode('')
    this.setProductProformDataCategory('')

    Object.keys(product.proformData.proformTypes).map(item => {
      if(proformItem.values[item])
        product.proformData.proformTypes[item].typeValue = ''
    })
    return
  } else {
    product.proformArchived = false
  }

  this.setProductProformDataCategory(proformItem.proform_category)
  this.setHSCode(proformItem.proformOptions.hs_code)

  /**
   * if not has values
   */
  if(!proformItem.values)
    return

  Object.keys(product.proformData.proformTypes).map(item => {
    if(proformItem.values[item])
      product.proformData.proformTypes[item].typeValue = proformItem.values[item][0]
  })

}



ProformProduct.prototype.setProfileItem = function (profileItem, isAdmin = false) {
  let product = this.product

  this.setProductHandMade(profileItem.handmade === '1')
  this.setProductPrice(profileItem.item_price)
  this.setProductItemsQuantity(profileItem.item_quantity)

  if (profileItem.archived === 1 && !isAdmin) {
    return
  }

  this.setHSCode(profileItem.hs_code)
  this.setProductProformDataCategory(profileItem.values[PROFORM_TYPE_NAMING].proform_attachment.proform_category)
  Object.keys(product.proformData.proformTypes).map(item => {
    if(profileItem.values[item]){
      product.proformData.proformTypes[item].typeValue = {
        proform_attachment_id: profileItem.values[item].proform_attachment_id,
        value: profileItem.values[item].proform_attachment.proform_value,
      }
    }
  })
}

ProformProduct.prototype.copyStartProfile = function () {
  this.copyProfile = this.prepareSaveProform()
}

ProformProduct.prototype.checkChangeProformWithCopy = function () {
  return JSON.stringify(this.copyProfile) === JSON.stringify(this.prepareSaveProform())
}

ProformProduct.prototype.setSystem = function (val) {
  this.product.system = val
}



/***************************   GETTERS     *****************************/


ProformProduct.prototype.getProductObject = function () {
  return this.product.productObject
}

ProformProduct.prototype.getProductName = function () {
  return this.product.productName
}

ProformProduct.prototype.getProductNameFromShop = function () {
  return this.product.productNameFromShop
}

ProformProduct.prototype.getProductPrice = function () {
  return this.product.price
}

ProformProduct.prototype.getProductItemsQuantity = function () {
  return this.product.itemsQuantity
}

ProformProduct.prototype.getHSCode = function () {
  return this.product.HSCode
}

ProformProduct.prototype.getProformEntityId = function () {
  return this.product.proformEntityId
}

ProformProduct.prototype.getNameForLabel = function () {
  return this.product.nameForLabel
}

ProformProduct.prototype.isSelectedProform = function () {
  if(Object.hasOwnProperty.call(this.product.proformData.proformTypes, '1') &&
    this.product.proformData.proformTypes[1]?.typeValue.proform_attachment_id){
    return true
  }
  return false
}

ProformProduct.prototype.getSystem = function () {
  return this.product.system
}



/***************************   Validation     *****************************/

ProformProduct.prototype.profileCreateValidation = function () {
  let validationItems = {
    proformCategory: this.product.proformData.proformCategory
  }

  let validationOptions = {
    proformCategory: {type: ['empty',]},
  }

  return this.checkValid(validationItems, validationOptions)
}

ProformProduct.prototype.productProformValidation = function (
  {
    productName,
    productObject,
    proformCategory,
    itemsQuantity,
    price,
    HSCode,
    productNameLatin,
    proformMaterial,
    proformArchived,
  }) {
  let valid = true
  let validationItems = {
    productName: this.product.productName,
    productObject: this.product.productObject,
    proformCategory: this.product.proformData.proformCategory,
    itemsQuantity: this.product.itemsQuantity,
    price: this.product.price,
    HSCode: this.product.HSCode,
  }

  let validationOptions = {
    productName: {type: productName ? ['empty'] : []},
    productObject: {type: productObject ? ['empty'] : []},
    proformCategory: {type: proformCategory ? ['empty'] : []},
    itemsQuantity: {type: itemsQuantity ? ['not-zero', 'empty'] : []},
    price: {type: price ? ['not-zero', 'empty'] : []},
    HSCode: {type: HSCode ? ['numeric', 'empty'] : []},
  }

  if(productNameLatin){
    validationOptions['productName']['type'].unshift('latin')
  }

  if(
    validationItems.proformCategory.id === PROFORM_CATEGORY_SAVED ||
    validationItems.proformCategory.id === PROFORM_CATEGORY_SEARCH
  ){
    validationItems.proformNaming = this.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue
    validationOptions.proformNaming = {type: ['empty']}
  }

  if (proformMaterial) {
    validationItems.proformMaterial = this.product.proformData.proformTypes[PROFORM_TYPE_MATERIAL].typeValue
    validationOptions.proformMaterial = {type: ['empty']}
  }

  if(this.product.proformArchived && !proformArchived) {
    this.validation.proformArchived = true
    this.validationTranslate.proformArchived = 'error_pleaseUpdateProform'
    valid = false
  } else {
    this.validation.proformArchived = false
  }


  return this.checkValid(validationItems, validationOptions) && valid
}

ProformProduct.prototype.productCreateFirstValidation = function () {
  let valid = true
  let validationItems = {
    productName: this.product.productName,
    proformCategory: this.product.proformData.proformCategory,
    HSCode: this.product.HSCode,
  }

  let validationOptions = {
    productName: {type: ['latin', 'empty']},
    proformCategory: {type: ['empty']},
    HSCode: {type: ['numeric', 'empty']},
  }

  if(this.product.proformArchived) {
    this.validation.proformArchived = true
    this.validationTranslate.proformArchived = 'error_pleaseUpdateProform'
    valid = false
  } else {
    this.validation.proformArchived = false
  }

  if(
    validationItems.proformCategory.id === PROFORM_CATEGORY_SAVED ||
    validationItems.proformCategory.id === PROFORM_CATEGORY_SEARCH
  ){
    validationItems.proformNaming = this.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue
    validationOptions.proformNaming = {type: ['empty']}
  }

  validationItems.proformMaterial = this.product.proformData.proformTypes[PROFORM_TYPE_MATERIAL].typeValue
  validationOptions.proformMaterial = {type: ['empty']}

  return this.checkValid(validationItems, validationOptions) && valid
}

ProformProduct.prototype.productCreateFirstValidationUser = function () {

  let validationItems = {
    user: this.product.userId,
  }

  let validationOptions = {
    user: {type: ['empty']}
  }

  return this.checkValid(validationItems, validationOptions)
}

ProformProduct.prototype.productCreateSecondValidation = function () {
  let validationItems = {
    // SKU: this.product.SKU
  }

  let validationOptions = {
    // SKU: {type: ['empty']},
  }

  if(this.product.isUPC){
    validationItems.UPC = this.product.UPC
    validationOptions.UPC = {type: ['empty']}
  }

  return (this.checkValid(validationItems, validationOptions) && this.product.Files.fileSizeValidation())
}

ProformProduct.prototype.checkValid = function(validationItems, validationOptions) {
  let validate = validation(validationItems, validationOptions)

  Object.keys(validationItems).map(item => {
    this.validation[item] = validate.validation[item] ? validate.validation[item] : false
    this.validationTranslate[item] = validate.validationError[item] ? validate.validationError[item] : ''
  })

  return validate.isValidate
}









/*********************************  PREPARE DATA  *********************************/

ProformProduct.prototype.prepareProduct = function (draft, userId = false, isAdmin = false) {
  let product = this.product

  let proform_data_ids = []
  Object.keys(product.proformData.proformTypes).map(item => {
    if(product.proformData.proformTypes[item].typeValue)
      proform_data_ids.push(product.proformData.proformTypes[item].typeValue.proform_attachment_id)
  })

  let data = {
    "user_id": userId ? userId : product.userId,
    "name" : product.productName,
    "name_from_shop" : product.productNameFromShop,
    "sku_code" : product.SKU,
    "upc_code" : product.UPC !== '' ? product.UPC : null,
    "hs_code" : product.HSCode !== '' ? product.HSCode : null,
    "in_draft" : draft ? 1 : 0,
    "proform_data" :[
      {
        "ids" : proform_data_ids,
        "handmade" : product.handMade ? 1 : 0,
        "item_quantity" : product.itemsQuantity,
        "item_price" : product.price
      },
    ]
  }

  console.log(isAdmin);
  // if(isAdmin) {
    data['medias'] = [
      {"media_url": product.medias[0].media_url},
      {"media_url": product.medias[1].media_url},
      {"media_url": product.medias[2].media_url},
      {"media_url": product.medias[3].media_url},
      {"media_url": product.medias[4].media_url},
    ]
  // }

  return data
}

ProformProduct.prototype.prepareProductWithSystemCheck = function (draft, userId = false) {
  let product = this.product

  let proform_data_ids = []
  Object.keys(product.proformData.proformTypes).map(item => {
    if(product.proformData.proformTypes[item].typeValue)
      proform_data_ids.push(product.proformData.proformTypes[item].typeValue.proform_attachment_id)
  })

  return {
    "user_id": userId ? userId : product.userId,
    "system": product.system ? product.system : '0',
    "name" : product.productName,
    "name_from_shop" : product.productNameFromShop,
    "sku_code" : product.SKU,
    "hs_code" : product.HSCode !== '' ? product.HSCode : null,
    "in_draft" : draft ? 1 : 0,
    "proform_data" :[
      {
        "ids" : proform_data_ids,
        "handmade" : product.handMade ? 1 : 0,
        "item_quantity" : product.itemsQuantity,
        "item_price" : product.price
      },
    ]
  }
}

ProformProduct.prototype.prepareSaveProform = function (withNameForLabel = false) {
  let product = this.product

  let proform_data_ids = []

  Object.keys(product.proformData.proformTypes).map(item => {
    if(product.proformData.proformTypes[item].typeValue !== undefined &&
        product.proformData.proformTypes[item].typeValue !== '' &&
        product.proformData.proformTypes[item].typeValue.proform_attachment_id)
      proform_data_ids.push(product.proformData.proformTypes[item].typeValue.proform_attachment_id)
  })

  let data = {
    "ids" : proform_data_ids,
    "handmade" : product.handMade ? 1 : 0,
    "hs_code" : product.HSCode !== '' ? product.HSCode : null,
    "item_quantity" : product.itemsQuantity,
    "item_price" : product.price,
  }

  if(withNameForLabel) {
    data['label_full_name'] = this.product.nameForLabel
  }

  return data
}


ProformProduct.prototype.generateNameForLabel = function (isAdmin = false) {
  if(this.product.nameForLabel.length > 0 && isAdmin) {
    return
  }
  this.product.nameForLabel = ''
  let proformTypes = this.product.proformData.proformTypes
  Object.keys(proformTypes).map((type, index) => {
    let name = proformTypes[type]?.typeValue?.value?.translationStorage?.en?.name
    if(name)  {
      this.product.nameForLabel += (index > 0 ? ' ' : '') + name
    }
  })
}

ProformProduct.prototype.changeSavedProform = function (proform) {
  let product = this.product

  this.setProductHandMade(proform.handmade === '1')
  this.setProductPrice(proform.item_price)
  this.setProductItemsQuantity(proform.item_quantity)

  if(proform?.archived === 1) {
    return
  }

  this.setHSCode(proform.hs_code)

  Object.keys(product.proformData.proformTypes).map(item => {
    let newVal = Object.assign({}, _.find(proform.values, {proform_type_id: parseInt(item)}))
    let proform_attachment_id = newVal.proform_attachment_id

    delete newVal.proform_attachment_id
    delete newVal.proform_type_id

    if(Object.keys(newVal).length > 0){
      product.proformData.proformTypes[item].typeValue = {
        proform_attachment_id: proform_attachment_id,
        value: newVal
      }
    } else {
      product.proformData.proformTypes[item].typeValue = ''
    }
  })
}
