// const moment = require('moment')
// const _ = require('lodash');

import {Product} from "./Product";
import {ProformProduct} from "./ProformProduct";
import {ProductCells} from "./ProductCells";
import _ from 'lodash'

export function ProductForCell(
  initializedProduct = false,
  initializedProformProduct = false,
  initialDamagedCount = false,
  initialScannedInKievCount = false
) {

  this.data = {
    id: '',

    checked: false,

    productCount: (initializedProduct || initializedProformProduct) ?
      initializedProduct ?
        initializedProduct.getProductCount() : initializedProformProduct.getProductItemsQuantity()
      : 0,
    damagedCount: initialDamagedCount ? initialDamagedCount : 0,
    scannedCount: 0,

    scannedInKievCount: initialScannedInKievCount ? initialScannedInKievCount : 0,

    UserCell: new ProductCells(),
    FreeCell: new ProductCells(),
    MixedCell: new ProductCells(),

    withoutCellDeclaredCounts: 0,
    withoutCellEndCounts: 0,

    /**
     * Array for disposal products
     */
    UserProductCellArray: [],
    UserMixedProductCellArray: [],
    UserCellArray: [],
    FreeCellArray: [],
    MixedCellArray: [],

    Product: initializedProduct ? initializedProduct : new Product(),
    ProformProduct: initializedProformProduct ? initializedProformProduct : new ProformProduct()
  }

  this.validation = {
    productCount: false,
    scannedCount: false,
    damagedCount: false,
  }

  this.validationTranslate = {
    productCount: '',
    scannedCount: '',
    damagedCount: '',
  }

  this.validationTxt = {
    productCount: false,
    scannedCount: false,
    damagedCount: false,
  }

  /**
   * Getters
   */

  this.getId = () => {
    return this.data.id
  }
  this.getProductCount = () => {
    return this.data.productCount
  }
  this.getDamagedCount = () => {
    return this.data.damagedCount
  }
  this.getScannedCount = () => {
    return this.data.scannedCount
  }

  /**
   * Setters
   */

  this.setId = function (val) {
    this.data.id = val
  }
  this.setProductCount = function (val) {
    this.data.productCount = val
  }
  this.setDamagedCount = function (val) {
    this.data.damagedCount = val
  }
  this.setScannedCount = function (val) {
    this.data.scannedCount = val
  }


}

/**
 * GLOBAL GETTERS
 */

ProductForCell.prototype.getProductCellsId = function () {
  if(this.data.UserCell.data.cellSelected)
    return this.data.UserCell.data.cellSelected?.id
  if(this.data.FreeCell.data.cellSelected)
    return this.data.FreeCell.data.cellSelected?.id
  if(this.data.MixedCell.data.cellSelected)
    return this.data.MixedCell.data.cellSelected?.id

  return false
}

ProductForCell.prototype.getProduct = function () {
  return this.data.Product
}

ProductForCell.prototype.getProformProduct = function () {
  return this.data.ProformProduct
}

/**
 * GLOBAL SETTERS
 */
ProductForCell.prototype.setItem = function (item) {
  console.log(item)
}

ProductForCell.prototype.setCellsByType = function (cells) {
  cells.map(cell => {
    if(!cell.data.cellSelected){
      return this.addScannedProductCount(cell.data.cellCount)
    }

    if(cell.data.cellSelected.mixed){
      this.data.MixedCellArray.push(cell)
      return this.addScannedProductCount(cell.data.cellCount)
    }

    if(cell.data.cellType === 'free') {
      this.data.FreeCellArray.push(cell)
      return this.addScannedProductCount(cell.data.cellCount)
    }

    this.data.UserCellArray.push(cell)
    this.addScannedProductCount(cell.data.cellCount)
  })
}


ProductForCell.prototype.setCellsByTypeInbound = function (cells) {
  let { userProductCells, userCells, freeCells, mixedFreeCells } = cells

  userProductCells.map(userProductCell => {

    if(!userProductCell.data.cellSelected){
      return this.addScannedProductCount(userProductCell.data.cellCount)
    }

    if(userProductCell.data.cellSelected.mixed){
      this.data.UserMixedProductCellArray.push(userProductCell)
      return this.addScannedProductCount(userProductCell.data.cellCount)
    }

    this.data.UserProductCellArray.push(userProductCell)
    this.addScannedProductCount(userProductCell.data.cellCount)
  })

  userCells.map(userCell => {
    if(!userCell.data.cellSelected){
      return this.addScannedProductCount(userCell.data.cellCount)
    }

    if(userCell.data.cellSelected.mixed){
      this.data.UserCellArray.push(userCell)
      return this.addScannedProductCount(userCell.data.cellCount)
    }

    this.data.UserCellArray.push(userCell)
    this.addScannedProductCount(userCell.data.cellCount)
  })

  freeCells.map(freeCell => {
    if(!freeCell.data.cellSelected){
      return this.addScannedProductCount(freeCell.data.cellCount)
    }

    if(freeCell.data.cellType === 'free') {
      this.data.FreeCellArray.push(freeCell)
      return this.addScannedProductCount(freeCell.data.cellCount)
    }
  })

  mixedFreeCells.map(freeCell => {
    if(!freeCell.data.cellSelected){
      return this.addScannedProductCount(freeCell.data.cellCount)
    }

    if(freeCell.data.cellSelected.mixed){
      this.data.MixedCellArray.push(freeCell)
      return this.addScannedProductCount(freeCell.data.cellCount)
    }
  })
}


ProductForCell.prototype.setProduct = function (val) {
  // this.data.Product = new Product()

  if(val) {
    this.data.Product.setProduct({
      id: val.product.id,
      name: val.product.name,
      description: val.product.description,
      count: val.product.count,
      price: val.product.price,
      sku_code: val.product.skuCode,
    })
  }
}

ProductForCell.prototype.setProformProduct = function (val) {
  // this.data.ProformProduct = new ProformProduct()
  if(val) {
    console.log(111111)
    // this.data.ProformProduct.setItem({
    //   id: val.product.id,
    //   name: val.product.name,
    //   description: val.product.description,
    //   count: val.product.count,
    //   price: val.product.price,
    //   sku_code: val.product.skuCode,
    // })
  }
}

/**
 * FUNCTIONS
 */

ProductForCell.prototype.copyProductCountInProductForCellProductCount = function () {
  this.data.productCount = this.data.Product.product.count
}

ProductForCell.prototype.addScannedProduct = function () {
  let productsForCellArray = this.data.UserCellArray
  let productsMixedForCellArray = this.data.MixedCellArray

  if(productsForCellArray && productsForCellArray.length > 0) {
    let foundFlag = false
    productsForCellArray.forEach(item => {
      if (+item.data.storageCount > +item.data.cellCount && !foundFlag) {
        this.data.scannedCount++
        item.data.cellCount++
        foundFlag = true
      }
    })

    if (foundFlag) return
  }

  if(productsMixedForCellArray && productsMixedForCellArray.length > 0) {
    let foundFlag = false
    productsMixedForCellArray.forEach(item => {
      if (+item.data.storageCount > +item.data.cellCount && !foundFlag) {
        this.data.scannedCount++
        item.data.cellCount++
        foundFlag = true
      }
    })

    if (foundFlag) return
  }

  this.data.scannedCount++
  this.data.withoutCellEndCounts = this.data.scannedCount
}

ProductForCell.prototype.addScannedProductInbound = function () {
  let foundProductsForCell = _.find(this.data.UserProductCellArray, function (item) {
    return Number(item.data.cellSelected?.row_name) > 20
  })
  // let firstProductsForCell = _.first(this.data.UserProductCellArray)
  // let firstProductsMixedForCell = _.first(this.data.UserMixedProductCellArray)
  // let firstUserCellArray = _.find(this.data.UserCellArray, {data: {cellSelected: {mixed: false}}}) || _.first(this.data.UserCellArray)
  // let firstFreeCell = _.first(this.data.FreeCellArray)
  // let firstMixedForCell = _.first(this.data.MixedCellArray)

  if(foundProductsForCell) {
    this.data.scannedCount++
    foundProductsForCell.data.cellCount++
    return true
  }

  this.data.withoutCellEndCounts++
  this.data.scannedCount++

  return false

  // if(firstFreeCell) {
  //   this.data.scannedCount++
  //   firstFreeCell.data.cellCount = this.data.scannedCount
  //   return
  // }
  //
  // if (firstMixedForCell) {
  //   this.data.scannedCount++
  //   firstMixedForCell.data.cellCount = this.data.scannedCount
  //   return
  // }
}

ProductForCell.prototype.addScannedProductToCell = function (cellId) {
  let productsInCells = 0

  let foundCellData = {arrayName: '', id: null}

  foundCellData = this.findCellById('UserProductCellArray', cellId)
  if (!foundCellData.id) foundCellData = this.findCellById('UserMixedProductCellArray', cellId)
  if (!foundCellData.id) foundCellData = this.findCellById('UserCellArray', cellId)
  if (!foundCellData.id) foundCellData = this.findCellById('MixedCellArray', cellId)
  if (!foundCellData.id) foundCellData = this.findCellById('FreeCellArray', cellId)

  //if no cell with given name - stop
  if (!foundCellData.id) return

  let arrayTypes = [
    'UserProductCellArray',
    'UserMixedProductCellArray',
    'UserCellArray',
    'MixedCellArray',
    'FreeCellArray',
  ]
  let foundProductsInCellsCount = []

  arrayTypes.map(type => {
    let foundCellCount = this.getProductsInCellsCount(type)
    if(foundCellCount.count > 0) {
      productsInCells += foundCellCount.count
      foundProductsInCellsCount.push(foundCellCount)
    }
  })

  productsInCells += this.data.withoutCellEndCounts

  // let foundCellCount = this.getProductsInCellsCount('UserProductCellArray')
  // if(foundCellCount.count > 0) {
  //   productsInCells += foundCellCount.count
  //   foundProductsInCellsCount.push(foundCellCount)
  // }
  //
  // foundCellCount = this.getProductsInCellsCount('UserMixedProductCellArray')
  // if(foundCellCount.count > 0) {
  //   productsInCells += foundCellCount.count
  //   foundProductsInCellsCount.push(foundCellCount)
  // }
  //
  // foundCellCount = this.getProductsInCellsCount('UserCellArray')
  // if(foundCellCount.count > 0) {
  //   productsInCells += foundCellCount.count
  //   foundProductsInCellsCount.push(foundCellCount)
  // }
  //
  // foundCellCount = this.getProductsInCellsCount('MixedCellArray')
  // if(foundCellCount.count > 0) {
  //   productsInCells += foundCellCount.count
  //   foundProductsInCellsCount.push(foundCellCount)
  // }
  //
  // foundCellCount = this.getProductsInCellsCount('FreeCellArray')
  // if(foundCellCount.count > 0) {
  //   productsInCells += foundCellCount.count
  //   foundProductsInCellsCount.push(foundCellCount)
  // }

  // if(this.getProductsInCellsCount('UserProductCellArray', cellId).count > 0)
  //   foundProductsInCellsCount.push(this.getProductsInCellsCount('UserProductCellArray', cellId))
  // if(this.getProductsInCellsCount('UserMixedProductCellArray', cellId).count > 0)
  //   foundProductsInCellsCount.push(this.getProductsInCellsCount('UserMixedProductCellArray', cellId))
  // if(this.getProductsInCellsCount('UserCellArray', cellId).count > 0)
  //   foundProductsInCellsCount.push(this.getProductsInCellsCount('UserCellArray', cellId))
  // if(this.getProductsInCellsCount('MixedCellArray', cellId).count > 0)
  //   foundProductsInCellsCount.push(this.getProductsInCellsCount('MixedCellArray', cellId))
  // if(this.getProductsInCellsCount('FreeCellArray', cellId).count > 0)
  //   foundProductsInCellsCount.push(this.getProductsInCellsCount('FreeCellArray', cellId))
  //
  //
  // foundProductsInCellsCount.map(item => {
  //   productsInCells += Number(item.count)
  // })

  // //let notScannedCount = this.data.scannedCount - productsInCells
  // let notScannedCount = +this.data.withoutCellEndCounts
  //
  // //if no new scanned products to put in cell - return
  // if (notScannedCount === 0) return

  //if no new scanned products to put in cell - return
  if (productsInCells === 0) return

  this.data[foundCellData.arrayName] = this.data[foundCellData.arrayName].map(item => {
    if (item.data?.cellSelected?.id === foundCellData.id) {
      /**
       * Reset scanned count before
       */
      foundProductsInCellsCount.map(productsInCellsCountItem => {
        this.resetScannedCount(productsInCellsCountItem.cellArrayName)
      })
      this.data.withoutCellEndCounts = 0

      item.data.cellCount = Number(item.data.cellCount) + Number(productsInCells)
    }

    return item
  })

  //put first for select
  this.data[foundCellData.arrayName] = this.data[foundCellData.arrayName].sort((a,b) => {
    return a.data?.cellSelected?.id === foundCellData.id ? -1 : b.data?.cellSelected?.id === foundCellData.id ? 1 : 0
  })
}

ProductForCell.prototype.getProductsInCellsCount = function (cellArrayName) {
  let count = 0
  this.data[cellArrayName].forEach(item => {
    count += item?.data?.cellCount || 0
  })

  return {count, cellArrayName}
}

ProductForCell.prototype.resetScannedCount = function (cellArrayName) {
  this.data[cellArrayName].map(item => {
    item.data.cellCount = 0
  })
}

ProductForCell.prototype.findCellById = function (cellArrayName, cellId) {
  let foundCell = this.data[cellArrayName].find(item => {
    return (item.data?.cellSelected?.id && +item.data.cellSelected.id === +cellId)
  })

  if (foundCell) {
    return {arrayName: cellArrayName, id: foundCell.data.cellSelected.id}
  }
  else {
    return {arrayName: '', id: null}
  }
}

ProductForCell.prototype.addScannedProductInventory = function () {
  let userProductCell = false
  let userMixedProductCell = false
  let userProductWithoutCell = false

  this.data.UserProductCellArray.map((cell, index) => {
    if(userProductCell) return

    if(cell.data.cellCount < cell.data.storageCount) {
      return userProductCell = {cell: cell, over: false}
    }

    if(cell.data.cellCount === cell.data.storageCount && index !== this.data.UserProductCellArray.length - 1) {
      return userProductCell = false
    }

    if(cell.data.cellCount >= cell.data.storageCount) {
      return userProductCell = {cell: cell, over: true}
    }
  })

  this.data.UserMixedProductCellArray.map((cell, index) => {
    if(userMixedProductCell) return

    if(cell.data.cellCount < cell.data.storageCount) {
      return userMixedProductCell = {cell: cell, over: false}
    }

    if(cell.data.cellCount === cell.data.storageCount && index !== this.data.UserMixedProductCellArray.length - 1) {
      return userMixedProductCell = false
    }

    if(cell.data.cellCount >= cell.data.storageCount) {
      return userMixedProductCell = {cell: cell, over: true}
    }
  })

  if(this.data.withoutCellDeclaredCounts > 0 ){
    userProductWithoutCell = true
  }
  if(this.data.withoutCellEndCounts >= this.data.withoutCellDeclaredCounts) {
    userProductWithoutCell = {over: true}
  }


  if((userMixedProductCell && !userMixedProductCell?.over) || (userMixedProductCell?.over && !userProductWithoutCell && !userProductCell)) {
    this.data.scannedCount++
    userMixedProductCell.cell.data.cellCount++
    return
  }

  if((userProductWithoutCell && !userProductWithoutCell?.over) || (userProductWithoutCell?.over && !userProductCell)) {
    this.data.scannedCount++
    this.data.withoutCellEndCounts++
    return
  }

  if(userProductCell) {
    this.data.scannedCount++
    userProductCell.cell.data.cellCount++
  }
}

ProductForCell.prototype.removeScannedProduct = function () {
  this.data.scannedCount--
}

ProductForCell.prototype.addScannedProductCount = function (val) {
  this.data.scannedCount = parseInt(this.data.scannedCount) + parseInt(val)
}
ProductForCell.prototype.allScannedProduct = function () {
  this.data.scannedCount = this.data.productCount
}
ProductForCell.prototype.removeAllScannedProduct = function () {
  this.data.scannedCount = 0
}

ProductForCell.prototype.getCellArraysCounts = function () {
  let
    userProductCellArray = this.data.UserProductCellArray,
    userMixedProductCellArray = this.data.UserMixedProductCellArray,
    userCellArray = this.data.UserCellArray,
    mixedCellArray = this.data.MixedCellArray,
    freeCellArray = this.data.FreeCellArray,
    userCellCounts = 0,
    mixedCellCounts = 0,
    freeCellCounts = 0

  userProductCellArray.map(item => {
    userCellCounts = userCellCounts + parseInt(item.data.cellCount)
  })

  userMixedProductCellArray.map(item => {
    userCellCounts = userCellCounts + parseInt(item.data.cellCount)
  })

  userCellArray.map(item => {
    userCellCounts = userCellCounts + parseInt(item.data.cellCount)
  })

  mixedCellArray.map(item => {
    mixedCellCounts = mixedCellCounts + parseInt(item.data.cellCount)
  })

  freeCellArray.map(item => {
    freeCellCounts = freeCellCounts + parseInt(item.data.cellCount)
  })

  return {userCellCounts, mixedCellCounts, freeCellCounts}
}

ProductForCell.prototype.checkRatioCellArraysCounts = function () {
  let userCellArray = this.data.UserCellArray,
    mixedCellArray = this.data.MixedCellArray,
    validRatioCount = true

  userCellArray.map(item => {
    if(!item.checkRatioCellCountAndStorage()){
      validRatioCount = false
    }
  })

  mixedCellArray.map(item => {
    if(!item.checkRatioCellCountAndStorage()){
      validRatioCount = false
    }
  })

  return validRatioCount
}

ProductForCell.prototype.setProductProformTypes = function (val) {
  this.data.ProformProduct.setProductProformTypes(val)
}

/**
 * VALIDATIONS
 */

ProductForCell.prototype.productValidation = function () {

  return this.data.Product.productPartialValidate({productObject: true})
}

ProductForCell.prototype.proformProductValidation = function () {

  return this.data.ProformProduct.productProformValidation({productObject: true})
}

ProductForCell.prototype.cellsValidation = function () {
  let valid = true,
    userCell = this.data.UserCell,
    freeCell = this.data.FreeCell,
    mixedCell = this.data.MixedCell

  if(!userCell.validationSelected()) valid = false
  if(!freeCell.validationSelected()) valid = false
  if(!mixedCell.validationSelected()) valid = false

  let productsCountInCells =
    parseInt(userCell.data.cellCount) +
    parseInt(freeCell.data.cellCount) +
    parseInt(mixedCell.data.cellCount)

  let validateProductScanned = productsCountInCells !== this.data.damagedCount + this.data.scannedCount
  this.validation.scannedCount = validateProductScanned
  this.validation.damagedCount = validateProductScanned
  if(validateProductScanned){
    valid = false
  }

  return (valid)
}

ProductForCell.prototype.cellsArrayValidation = function () {
  let valid = true,
    // userProductCellCounts,
    // userProductMixedCellCounts,
    userCellCounts,
    mixedFreeCellCounts,
    freeCellCounts

  let cellCounts = this.getCellArraysCounts()
  userCellCounts = cellCounts.userCellCounts
  mixedFreeCellCounts = cellCounts.mixedCellCounts
  freeCellCounts = cellCounts.freeCellCounts

  let productCountInCells = userCellCounts + mixedFreeCellCounts + freeCellCounts

  let productCountWithoutCells = parseInt(this.data.withoutCellEndCounts)

  let validateProductScanned = productCountInCells + this.data.damagedCount + productCountWithoutCells !== this.data.scannedCount
  let validateProductCount = this.data.productCount !== this.data.scannedCount

  if(validateProductScanned){
    // validateProductScanned ||
    // this.validation.productCount = validateProductCount
    this.validation.damagedCount = validateProductScanned
    this.setValidErrorInAllCellCounts(true)
    valid = false

  }
  if(validateProductCount) {
    this.validation.scannedCount =  validateProductCount
    valid = false
  }

  if(valid){
    this.setValidErrorInAllCellCounts(false)
    this.validation.scannedCount = false
    this.validation.damagedCount = false
    this.validation.productCount = false
  }

  return (valid)
}

ProductForCell.prototype.setValidErrorInAllCellCounts = function (val) {
  this.data.UserCellArray.map(cell => {
    cell.validation.cellCount = val
  })
  this.data.MixedCellArray.map(cell => {
    cell.validation.cellCount = val
  })
  this.data.FreeCellArray.map(cell => {
    cell.validation.cellCount = val
  })
}


ProductForCell.prototype.productCountForMoveValidation = function () {
  let countDefineProduct = 0
  let countProductAfterMove = 0

  this.data.UserProductCellArray.map(cell => {
    countDefineProduct += parseInt(cell.data.storageCount)
    countProductAfterMove += parseInt(cell.data.cellCount)
  })

  this.data.UserMixedProductCellArray.map(cell => {
    countDefineProduct += parseInt(cell.data.storageCount)
    countProductAfterMove += parseInt(cell.data.cellCount)
  })

  this.data.UserCellArray.map(cell => {
    countProductAfterMove += parseInt(cell.data.cellCount)
  })
  this.data.FreeCellArray.map(cell => {
    countProductAfterMove += parseInt(cell.data.cellCount)
  })
  this.data.MixedCellArray.map(cell => {
    countProductAfterMove += parseInt(cell.data.cellCount)
  })

  countDefineProduct += parseInt(this.data.withoutCellDeclaredCounts)
  countProductAfterMove += parseInt(this.data.withoutCellEndCounts)

  return countDefineProduct === countProductAfterMove

}




/**
 * PREPARE DATA
 */


