
<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line ">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getProductsLoading === true}">

        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--              <span class="admin-edit" @click="editTranslate([item.label])"></span>-->
              <template>
                {{item.label}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
        <DefaultCheckbox
                :label="$t('warehouseManagement_selectAll.localization_value.value')"
                @input="(val) => $emit('selectAll', val)"
        />
      </div>
      <div class="btn-right-block">
<!--        <div class="content-top-line-wrap ml-2"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate([-->
<!--          'common_import',-->
<!--        ])"></span>-->
<!--          <TableHeadButton-->
<!--            :value="$t('common_import.localization_value.value')"-->
<!--            :ico="'import'"-->
<!--            @click.native="changeImportPopup(true)"-->
<!--          />-->
<!--        </div>-->
        <div v-if="isAdmin"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'product_UPC',
          ])"></span>
          <DefaultInput
              class="mr-2 btn-right-block-input"
              style="max-width: 150px;"
              :label="$t('product_UPC.localization_value.value')"
              :type="'text'"
              :name="'upcProduct'"
              @change="findProductByUPC"
          />
        </div>
        <div v-if="isAdmin"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'product_goodsRelocation',
          ])"></span>
            <MainButton class="btn-fit-content brown mr-3"
                        :value="$t('product_goodsRelocation.localization_value.value')"
                        @click.native="$emit('goodsRelocation')"
            >
            </MainButton>
        </div>
        <div class="content-top-line-btn ml-2 mr-2"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_termalPrint',
        ])"></span>
          <TableHeadButton
              :value="$t('common_termalPrint.localization_value.value')"
              :ico="'print'"
              @click.native="$emit('massThermalPrint')"
          />
        </div>

        <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'common_AddNew',
          ])"></span>
          <router-link :to="$store.getters.GET_PATHS.productsCreate">
            <MainButton class="btn-fit-content"
                        :value="$t('common_AddNew.localization_value.value')"
                        :ico="'ico'"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus" />
              </template>
            </MainButton>
          </router-link>
        </div>
      </div>
    </div>


    <MoveProductCell
        v-if="moveProductCell"
        :product="moveProduct"
        :navTabs="navTabs"
        @close="moveProductCell = false"
        @moveSuccess="moveProductCell = false; $emit('reload')"
    />

<!--    <ImportPopup-->
<!--        v-if="isModalImportPopup"-->
<!--        @close="changeImportPopup(false)"-->
<!--        @reloadTable="$emit('reloadTable')"-->
<!--    />-->
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import TableHeadButton from "../../../../../../coreComponents/TableComponents/TableHeadButton/TableHeadButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import MoveProductCell from "@/components/modules/ProductsModule/chunks/MoveProductCell/MoveProductCell";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";


  export default {
    name: "ProductsHead",
    components: {
      DefaultInput,
      MoveProductCell,
      // ImportPopup,
      DefaultCheckbox,
      MainButton,
      ToggleFilterButton,
      PlusIconSVG,
      // PrintButton,
      // DefaultCheckbox,
      TableHeadButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {

    },

    created() {

    },

    data() {
      return {
        isModalImportPopup: false,

        moveProductCell: false,

        moveProduct: null,
      }
    },



    methods: {

      findProductByUPC(val) {
        if(val.length === 0) return

        const query = new this.Query()
        let url = '?'
        let myQuery = query
            .for('posts')
        let storageId = this._.find(this.navTabs, {active: true}).name

        // myQuery.where('ProductUserId', this.product.user.id)
        myQuery.where('ProductUpcCode', val)
        myQuery.where('byStorageId', storageId)
        url = url + myQuery.limit(1).page(parseInt(0)).url().split('?')[1]
        this.$store.dispatch('fetchProducts', url).then((response) => {
          console.log(this.getRespPaginateData(response));
          if(this.getRespPaginateData(response).length > 0) {
            this.moveProduct = this._.first(this.getRespPaginateData(response))
            console.log(this.moveProduct);
            this.moveProduct['user'] = {id: this.moveProduct.user_id}
            this.moveProductCell = true
          }
        }).catch(error => this.createErrorLog(error))

      },


      changeTab(val) {
        this.$emit('changeTab', val)
      },

      selectAll(val) {
        this.$emit('selectAll', val)
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },

      changeImportPopup(val){
        this.isModalImportPopup = val
      },
    }
  }
</script>


<style scoped lang="scss">

  @media (max-width: 768px) {
    .light-bg-block {
      flex-direction: column;
      align-items: flex-start;
    }

    .btn-right-block {
      margin-left: 0;
      margin-top: 7px;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .btn-right-block > .admin-edit-item {
      margin-top: 5px;
    }

    .btn-right-block .main-button {
      margin-left: 0 !important;
    }
  }

  @media (max-width: 992px) {
    .btn-right-block .content-top-line-wrap{
      top: 0;
    }
    //.table-head-btn__btn.btn-style{
    //  font-size: 0;
    //}
  }

  @media (max-width: 680px) {
    .btn-right-block-input {
      height: 36px;
    }

    /deep/ .btn-right-block-input .default-input-wrap__label {
      top: 10px;
    }

    /deep/ .btn-right-block-input input {
      font-size: 14px !important;
      height: 36px;

      &::placeholder {

      }
    }
  }
</style>
