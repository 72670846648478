<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
          :name="'Shipment ID'"
          :value="'#180378'"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <div class="card-detail-left__date mt-1">
            20 Oct, 2020
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.products" class="detail-page__head-back">
                  <LinkBack
                      :value="'Back to Products' "
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title color-brown">
              Import
            </div>
            <div class="order-create__head-txt mb-0">
              Please, fill out all the information below to import new product
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="order-create__section-label section-label mb-3">
              Import File
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col">
                <div class="mb-3 fsz14">Data format CVS file with fields</div>

                <DropZone class="drop-zone-bg"
                          :parentFiles="files"
                          @changeImg="changeImg"
                          :maxSize="'5'"
                />
              </div>
            </div>


          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.products"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto">

              <MainButton
                  class="order-create__footer-btn-i"
                  :value="'Submit'"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import DropZone from "../../../../../coreComponents/DropZone/DropZone";



  export default {
    name: "ProductsImportAdmin",

    components: {
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      DropZone,
    },

    mixins: [mixinDetictingMobile],

    data() {
      return {
        files: [],
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>

