var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"content-top-line content-top-line--with-bottom-line"},[_c('div',{staticClass:"content-tabs content-tabs--separator",class:{'loading-tabs': _vm.$store.getters.getProductsLoading === true}},[_vm._l((_vm.navTabs),function(item,index){return [_c('div',{key:index,staticClass:"content-tabs__item",class:{active: item.active},on:{"click":function($event){return _vm.$emit('changeTab', item.name)}}},[_c('div',{staticClass:"content-tabs__ico",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[[_vm._v(" "+_vm._s(item.label)+" ")]],2)])]})],2),_c('div',{staticClass:"content-top-line-wrap"},[_c('ToggleFilterButton',{attrs:{"count":_vm.countFilterParams,"showFilter":_vm.showFilter},on:{"toggleFilter":function($event){return _vm.$emit('toggleFilter')}}})],1)]),_c('div',{staticClass:"light-bg-block light-bg-block--mobile-wrap"},[_c('div',{staticClass:"btn-left-block"},[_c('DefaultCheckbox',{attrs:{"label":_vm.$t('warehouseManagement_selectAll.localization_value.value')},on:{"input":(val) => _vm.$emit('selectAll', val)}})],1),_c('div',{staticClass:"btn-right-block btn-right-block--mobile-wrap"},[_c('div',{staticClass:"content-top-line-btn ml-2",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()},staticStyle:{"position":"relative"}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'common_import',
      ])}}}),_c('TableHeadButton',{attrs:{"value":_vm.$t('common_import.localization_value.value'),"ico":'import'},nativeOn:{"click":function($event){return _vm.changeImportPopup(true)}}}),_c('div',{staticClass:"likeTooltipBtn"},[_c('TableHeadButton',{staticClass:"likeTooltipBtn__item",attrs:{"value":'',"ico":'question'},nativeOn:{"click":function($event){return _vm.downloadExample.apply(null, arguments)}}})],1)],1),_c('div',{staticClass:"content-top-line-btn ml-2",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'common_termalPrint',
      ])}}}),_c('TableHeadButton',{attrs:{"value":_vm.$t('common_termalPrint.localization_value.value'),"ico":'print'},nativeOn:{"click":function($event){return _vm.$emit('massThermalPrint')}}})],1),_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'common_AddNew',
        ])}}}),_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.productsCreate}},[_c('MainButton',{staticClass:"btn-fit-content ml-2",attrs:{"value":_vm.$t('common_AddNew.localization_value.value'),"ico":'ico'}},[_c('template',{slot:"ico"},[_c('PlusIconSVG',{staticClass:"btn-icon-plus"})],1)],2)],1)],1)])]),(_vm.isModalImportPopup)?_c('ImportPopup',{on:{"close":function($event){return _vm.changeImportPopup(false)},"reloadTable":function($event){return _vm.$emit('reloadTable')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }